
import { defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DataTable, Column, Card, Tooltip } from '@/plugins/PrimeVue';

import useInvoiceTemplates from '@/composable/useInvoiceTemplates';
import useInvoiceTemplate from '@/composable/useInvoiceTemplate';
import { InvoiceTemplate } from '@/interfaces';
import useOrganizations from "@/composable/useOrganizations";

export default defineComponent({
	components: {
		DataTable,
		Column,
		Card,
	},
	directives: {
		tooltip: Tooltip,
	},
	setup() {
		const router = useRouter();
		const { invoiceTemplates, getInvoiceTemplates } = useInvoiceTemplates();
		const { deleteInvoiceTemplate } = useInvoiceTemplate();
		const { selectedOrganization } = useOrganizations();

		watch(selectedOrganization, () => {
			getInvoiceTemplates();
		});

		const toNew = () => {
			router.push({ name: 'invoice-template-create' });
		};

		const toView = (id: number) => {
			router.push({ name: 'invoice-template-detail', params: { id } });
		};

		const toEdit = (id: number) => {
			router.push({ name: 'invoice-template-edit', params: { id } });
		};

		const toDelete = async (invoiceTemplate: InvoiceTemplate) => {
			await deleteInvoiceTemplate(invoiceTemplate)
		};

		return { invoiceTemplates, toNew, toEdit, toDelete, toView };
	},
});

import { ApiInstance } from '@/plugins/Axios';
import { HttpInvoice } from '@/interfaces';

// TODO write url generation util
function setInvoiceApiURL(organizationId: number | undefined, invoiceId: number | null = null) {
	const baseUrl = `/api/organizations/${organizationId}/invoices/`;

	if (invoiceId) {
		return `${baseUrl}${invoiceId}`;
	} else {
		return baseUrl;
	}
}

export const InvoiceService = (api: ApiInstance) => ({
	async getAll(payload: number) {
		return api.get<HttpInvoice[]>(setInvoiceApiURL(payload));
	},
	async get(organizationId: number, invoiceId: number) {
		return await api.get<HttpInvoice>(setInvoiceApiURL(organizationId, invoiceId));
	},
	async create(payload: HttpInvoice) {
		return api.post(setInvoiceApiURL(payload.organizationId), payload);
	},
	async update(payload: HttpInvoice) {
		return api.patch<HttpInvoice>(setInvoiceApiURL(payload.organizationId, payload.id), payload);
	},
	async updateStatus(payload: HttpInvoice) {
		return api.post<HttpInvoice>(setInvoiceApiURL(payload.organizationId, payload.id) + '/status', {status: payload.status});
	},
});

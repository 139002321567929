import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "card w-full" }
const _hoisted_4 = { class: "col-12 mb-0" }
const _hoisted_5 = { class: "flex flex-column" }
const _hoisted_6 = { class: "field p-0 mx-5" }
const _hoisted_7 = {
  key: 0,
  class: "p-error ml-2"
}
const _hoisted_8 = { class: "field p-0 mx-5 flex flex-row" }
const _hoisted_9 = {
  style: {"align-items":"center","height":"35px"},
  class: "flex flex-row col-2 p-0"
}
const _hoisted_10 = {
  style: {"margin-left":"8px"},
  for: "use-client"
}
const _hoisted_11 = {
  key: 0,
  class: "field p-0 mx-5 flex flex-row"
}
const _hoisted_12 = { class: "w-full flex" }
const _hoisted_13 = { class: "col-6 p-0 pr-3" }
const _hoisted_14 = {
  key: 0,
  class: "card"
}
const _hoisted_15 = { class: "col-12 mb-0" }
const _hoisted_16 = { class: "p-px-6 flex flex-row w-6" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}
const _hoisted_18 = { class: "col-6 p-0 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InvoiceTemplateForm = _resolveComponent("InvoiceTemplateForm")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FormFields = _resolveComponent("FormFields")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t(`pages.invoiceTemplateEditor.formFieldEditor.${(_ctx.isNew ? 'newTemplate' : 'updateTemplate')}`)) + ": ", 1),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_InputText, {
              class: "col-5 p-2",
              placeholder: _ctx.$t('pages.invoiceTemplates.templateName'),
              id: "templateName",
              modelValue: _ctx.invoiceTemplate.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.invoiceTemplate.name = $event)),
              tabindex: 0,
              "data-testid": "invoice-template-name",
              required: ""
            }, null, 8, ["placeholder", "modelValue"]),
            (_ctx.errors['name'])
              ? (_openBlock(), _createBlock("small", _hoisted_7, _toDisplayString(_ctx.errors['name']), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_8, [
            _createVNode("div", _hoisted_9, [
              _createVNode(_component_CheckBox, {
                modelValue: _ctx.assignClient,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.assignClient = $event)),
                name: "use-client",
                binary: true,
                "data-testid": "is-for-client-toggle"
              }, null, 8, ["modelValue"]),
              _createVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("pages.invoiceTemplateEditor.useClient")) + ".", 1)
            ]),
            (_ctx.assignClient)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 0,
                  filter: "",
                  modelValue: _ctx.currentClient,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.currentClient = $event)),
                  options: _ctx.clients,
                  optionLabel: "name",
                  placeholder: _ctx.$t('pages.invoiceTemplateEditor.selectClient'),
                  class: "col-3 p-0",
                  "data-testid": "client-dropdown"
                }, null, 8, ["modelValue", "options", "placeholder"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.currentClient && _ctx.reminderContactPoints?.length > 0)
            ? (_openBlock(), _createBlock("div", _hoisted_11, [
                _createVNode(_component_Dropdown, {
                  filter: "",
                  modelValue: _ctx.selectedContactPoint,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedContactPoint = $event)),
                  options: _ctx.reminderContactPoints,
                  optionLabel: "email",
                  placeholder: "SELECT_MAIL",
                  class: "col-5 p-0",
                  "data-testid": "reminder-mail-dropdown"
                }, {
                  option: _withCtx((slotProps) => [
                    _createVNode("div", null, _toDisplayString(slotProps.option.email), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode("div", _hoisted_12, [
        _createVNode("div", _hoisted_13, [
          (_ctx.invoiceTemplate)
            ? (_openBlock(), _createBlock("div", _hoisted_14, [
                _createVNode("h5", _hoisted_15, _toDisplayString(_ctx.title), 1),
                _createVNode("form", {
                  onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
                  class: "p-fluid ml-3"
                }, [
                  _createVNode(_component_InvoiceTemplateForm, {
                    invoiceTemplate: _ctx.invoiceTemplate,
                    errors: _ctx.errors,
                    "onUpdate:invoiceTemplate": _ctx.updateInvoiceInputFields
                  }, null, 8, ["invoiceTemplate", "errors", "onUpdate:invoiceTemplate"]),
                  _createVNode("div", _hoisted_16, [
                    _createVNode(_component_Button, {
                      icon: "pi pi-check",
                      label: _ctx.saveButtonLabel,
                      class: "p-mr-2 m-1",
                      "data-testid": "submit",
                      onClick: _ctx.submit
                    }, null, 8, ["label", "onClick"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      label: "Annuleer",
                      class: "p-button-secondary m-1",
                      onClick: _ctx.back
                    }, null, 8, ["onClick"])
                  ]),
                  (_ctx.errors['detail'])
                    ? (_openBlock(), _createBlock("small", _hoisted_17, _toDisplayString(_ctx.errors['detail']), 1))
                    : _createCommentVNode("", true)
                ], 32)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode("div", _hoisted_18, [
          _createVNode(_component_FormFields)
        ])
      ])
    ])
  ]))
}
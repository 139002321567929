
import { defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, PButton } from '@/plugins/PrimeVue';
import ClientDetail from '@/components/ClientDetail/ClientDetail.vue';
import useClient from '@/composable/useClient';

export default defineComponent({
	components: {
		Panel,
		ClientDetail,
		PButton,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const title = ref(route.meta.title);
		const clientId = +route.params.id || 0;

		const {
			client,
			errors,
		} = useClient(true, clientId);

		const back = () => {
			router.push({ name: 'clients' });
		};

		const toEdit = () => {
			router.push({ name: 'client-edit', params: { clientId } });
		};

		return {
			title,
			client,
			clientId,
			back,
			toEdit,
			errors,
		};
	},
});


import { defineComponent, ref, watch } from "vue";
import { InputNumber } from "@/plugins/PrimeVue";
import { isEqual } from "lodash";

export default defineComponent({
	name: 'setInvoiceReminder',
	components: {
		InputNumber
	},
	props: {
		paymentTerms: {
			type: Object as () => number[],
			required: true,
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
	},
	setup(props, { emit }) {
		const paymentTerms = ref(props.paymentTerms);

		watch(() => props.paymentTerms, () => {
			paymentTerms.value = props.paymentTerms;
		});

		watch(paymentTerms.value, () => {
			emit('update:errors', paymentTerms.value);
			emit('update:paymentTerms', paymentTerms.value);
		});

		return {
			paymentTerms
		}
	},
});

import { CustomField } from "@/interfaces"
import { MutationTree } from "vuex";
import { State } from "./state";

export enum MutationTypes {
	SET_ALL = "SET_ALL_CUSTOM_FIELDS",
	SET_SINGLE = "SET_SINGLE_CUSTOM_FIELD",
	DELETE_SINGLE = "DELETE_SINGLE_CUSTOM_FIELD",
	SET_ERRORS = "SET_ERRORS_CUSTOM_FIELDS"
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: CustomField[]): void;
	[MutationTypes.SET_SINGLE](state: S, payload: CustomField): void;
	[MutationTypes.DELETE_SINGLE](state: S, payload: CustomField): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
}

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ALL](state, payload) {
		state.customFields = payload;
	},
	[MutationTypes.SET_SINGLE](state, payload) {
		const isNew = !state.customFields.find((template: CustomField) => template.id === payload.id);
		if (isNew) {
			state.customFields.push(payload);
			return;
		}
		state.customFields = state.customFields.map((customField: CustomField) => {
			if (customField.id !== payload.id) {
				return customField;
			}
			return payload;
		});
	},
	[MutationTypes.DELETE_SINGLE](state, payload) {
		state.customFields = state.customFields.filter((customField: CustomField) => {
			if (customField.id !== payload.id) {
				return customField;
			}
		});
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
}

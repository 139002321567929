
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DataTable, Column, Card, Tooltip, Dialog } from '@/plugins/PrimeVue';
import { useConfirm } from 'primevue/useconfirm';
import { Client, Organization } from '@/interfaces';
import useOrganizations from '@/composable/useOrganizations';
import useOrganization from '@/composable/useOrganization';
import ConfirmInvoice from '@/components/ConfirmInvoice/ConfirmInvoice.vue';
import { dataTableFilter } from "@/utils/dataTableFilter";
import useClients from '@/composable/useClients';

export default defineComponent({
	components: {
		DataTable,
		Column,
		Card,
		Dialog,
		ConfirmInvoice,
	},
	directives: {
		tooltip: Tooltip,
	},
	setup() {
		const router = useRouter();
		const { organizations, getOrganizations, errors } = useOrganizations();
		const { tempClients, getClients } = useClients();
		const confirm = useConfirm();
		const filter = ref(dataTableFilter());
		const loading = ref(false);
		const { deleteOrganization } = useOrganization();

		const indexedOrganizations = ref({})

		watch(organizations, () => {
			indexedOrganizations.value = organizations.value.reduce((acc, curr) => {
				// @ts-ignore-next-line
				acc[curr.id] = false;
				return acc;
			}, {});
		})

		const hasLinkedClient = (tempClients: Client[], organizationId: number): boolean => {
			return tempClients.some((client: Client) => {
				return client.organization === organizationId})
		};

		const toNew = () => {
			router.push({ name: 'organization-create' });
		};

		const toEdit = (id: number) => {
			router.push({ name: 'organization-edit', params: { id } });
		};

		const toView = (id: number) => {
			router.push({ name: 'organization-view', params: { id } });
		};

		const toDelete = async (organization: Organization) => {
			// @ts-ignore
			indexedOrganizations.value[organization.id] = true;

			const handle = watch(
				tempClients,
				async () => {
					handle();

					if(hasLinkedClient(tempClients.value, organization.id)) {
						confirm.require({
							message: `${organization.name} is gebonden aan één of meerdere klanten en kan niet worden verwijderd.`,
							icon: 'pi pi-info-circle',
							acceptClass: 'p-button-primary',
							accept: async () => {
								// @ts-ignore
								indexedOrganizations.value[organization.id] = false;
							},
							rejectClass: "hidden",
							acceptLabel: "Ok",
						});

						return;
					}

					confirm.require({
						message: `weet u zeker dat u deze ${organization.name} wilt verwijderen?`,
						icon: 'pi pi-info-circle',
						acceptClass: 'p-button-primary',
						accept: async () => {
							await deleteOrganization(organization.id);
							getOrganizations();
						},
						acceptLabel: "Ja",
						rejectLabel: "Nee"
					});
					// if (window.confirm('weet u zeker dat u deze organisatie wilt verwijderen?')) {
					// 	await deleteOrganization(organization.id);
					// 	getOrganizations();
					// }
				}
			);

			getClients(organization.id, true);
		};

		return {
			organizations,
			errors,
			toNew,
			toEdit,
			toView,
			toDelete,
			filter,
			loading,
			indexedOrganizations,
		};
	},
	methods: {
		clearFilter() {
			this.filter = dataTableFilter();
		},
	}
});

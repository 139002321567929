
import Draggable from "vuedraggable";
import { defineComponent, Ref, ref, watch } from "vue";
import { FormFields } from "@/interfaces/FormFields";
import { useI18n } from "vue-i18n";
import useCustomFields from "@/composable/useCustomFields"
import useCustomField from "@/composable/useCustomField"
import useOrganizations from "@/composable/useOrganizations";
import { CustomField } from "@/interfaces";
import { useConfirm } from "primevue/useconfirm";
import FormFieldIcon from "./FormFieldIcon.vue";


export default defineComponent({
	components: {
		Draggable,
		FormFieldIcon
	},
	name: "FormFields",
	setup() {
		const { t } = useI18n();
		const dragging: boolean = false;
		const fieldName = ref('');
		const fieldType = ref('');
		const { customFields, getCustomFields, deleteCustomField } = useCustomFields();
		const { selectedOrganization } = useOrganizations();
		const draggableFields = ref([] as CustomField[]);
		const { customField, saveCustomField } = useCustomField();
		const vueConfirm = useConfirm();
		const searchFilter = ref<string>('');
		const inputs = ref<CustomField[]>([]);

		interface inputType {
			type: string,
		};

		const selectedInputType = ref<inputType>({type: ''});

		watch(selectedOrganization, () => {
			getCustomFields();
		});

		watch([searchFilter, selectedInputType], () => {
			// TODO add translations
			switch(selectedInputType.value.type.toLowerCase()) {
				case 'text': return inputs.value = draggableFields.value.filter((f) => f.type === 'text' && f.key.toLowerCase().includes(searchFilter.value.toLowerCase()));
				case 'nummer': return inputs.value = draggableFields.value.filter((f) => f.type === 'number' && f.key.toLowerCase().includes(searchFilter.value.toLowerCase()));
				case 'beschrijving': return inputs.value = draggableFields.value.filter((f) => f.type === 'textarea' && f.key.toLowerCase().includes(searchFilter.value.toLowerCase()));
				case '' : return inputs.value = draggableFields.value.filter((f) => f.key.toLowerCase().includes(searchFilter.value.toLowerCase()));
				case 'default': return inputs.value = draggableFields.value.filter((f) => f.key.toLowerCase().includes(searchFilter.value.toLowerCase()));
			}
		});

		watch(customFields, () => {
			draggableFields.value = customFields.value;
			inputs.value = customFields.value
		});

		const FieldTypes = {
			text: { type: 'text', value: 'InputText', label: t('pages.invoiceTemplateEditor.formFieldEditor.fieldTypes.text') },
			number: { type: 'number', value: 'InputNumber', label: t('pages.invoiceTemplateEditor.formFieldEditor.fieldTypes.number') },
			textarea: { type: 'textarea', value: 'TextArea', label: t('pages.invoiceTemplateEditor.formFieldEditor.fieldTypes.textarea') },
		} as const;

		const groupedFormInputFields: FormFields[] = [
			FieldTypes.text,
			FieldTypes.number,
			FieldTypes.textarea,
		];

		// Fix type
		const inputTypes = ref([
			{ type: 'Text' },
			{ type: 'Nummer' },
			{ type: 'Beschrijving' },
		]);

		const cloneListItem = (el: CustomField) => {
			return {
				name: el.key,
				type: el.type,
			}
		};

		const deleteField = (id: number, key: string) => {
			// vueConfirm.require({
			// 	message: t('dialogs.deleteField', {f: key}),
			// 	rejectLabel: t("general.no"),
			// 	acceptLabel: t("general.yes"),
			//
			// 	icon: 'pi pi-exclamation-triangle',
			// 	accept: () => {
			// 		deleteCustomField(selectedOrganization.value.id, id);
			// 		getCustomFields();
			// 	},
			// 	reject: () => {}
			// });

			if (confirm(t('dialogs.deleteField', {f: key}))) {
				deleteCustomField(selectedOrganization.value.id, id);
				getCustomFields();
			}
		}

		const addField = async () => {
			if(fieldName.value === '') {
				alert('Vul alstublieft het label veld in.');
			} else {
				customField.value = {
					type: fieldType.value,
					key: fieldName.value,
					organization: selectedOrganization.value.id,
				}

				await saveCustomField();
				getCustomFields();

				fieldName.value = '';
			}
		}

		const setInputTypeFilter = (input: inputType) => {
			selectedInputType.value = input
		}

		const resetSearchValue = () => {
			searchFilter.value = ''
		}

		const resetFilter = () => {
			selectedInputType.value = {type: ''}
		}

		return {
			FieldTypes,
			dragging,
			groupedFormInputFields,
			draggableFields,
			fieldType,
			fieldName,
			searchFilter,
			inputs,
			selectedInputType,
			inputTypes,
			cloneListItem,
			addField,
			deleteField,
			setInputTypeFilter,
			resetSearchValue,
			resetFilter,
		}
	},
});


import { ClientUpdate, ContactPointUpdate, Organization } from '@/interfaces';
import { InputText, ListBox } from '@/plugins/PrimeVue';
import CheckBox from "primevue/checkbox";
import { isEqual } from 'lodash';
import { defineComponent, ref, watch, Ref } from 'vue';
import ContactPersonList from '@/components/ClientForm/ContactPersonList/ContactPersonList.vue';
import { createAddress } from "@/interfaces/Address";
import { createMailbox } from "@/interfaces/Mailbox";

export default defineComponent({
	components: {
		InputText,
		ListBox,
		CheckBox,
		ContactPersonList,
	},
	props: {
		client: {
			type: Object as () => ClientUpdate,
			required: false,
			default: {},
		},
		organizations: {
			type: Object as () => Organization[],
			required: false,
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
	},
	setup(props, { emit }) {
		const identicalAddress: Ref<Boolean> = ref(false);
		const InternalClient: Ref<ClientUpdate> = ref({ ...props.client });
		const input = ref();

		if(!InternalClient.value.id) {
			InternalClient.value.postal_address = createAddress();
			InternalClient.value.billing_address = createAddress();
			InternalClient.value.mailbox = createMailbox();
		}

		if (isEqual(InternalClient.value.postal_address, InternalClient.value.billing_address)) {
			identicalAddress.value = true;
		}

		const updateClient = (contactPoints: ContactPointUpdate[]) => {
			InternalClient.value.contacts = contactPoints;
		};

		watch(InternalClient, () => {
			InternalClient.value = props.client;
		});

		watch(InternalClient.value, () => {
			emit('update:errors', InternalClient.value);

			if ( identicalAddress.value && !isEqual(InternalClient.value.billing_address, InternalClient.value.postal_address) ) {
				InternalClient.value.billing_address = { ...InternalClient.value.postal_address };
			}

			if (!isEqual(props.client, InternalClient.value)) {
				emit('update:client', InternalClient.value);
			}
		});

		return {
			identicalAddress,
			InternalClient,
			input,
			updateClient,
		};
	},
});

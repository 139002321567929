import { ActionContext, ActionTree } from 'vuex';
import { isEqual } from 'lodash';
import { api } from '@/api';
import { RootState } from '@/store/state';
import { apiKeyVariables, Client, ClientUpdate } from '@/interfaces';
import { createError } from '@/utils/createError';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import { checkValidity, ValidationItem } from "@/utils/validityChecker";
import { i18n } from '@/plugins/i18n';

export enum ActionTypes {
	getAll = 'CLIENT__GET_ALL',
	getSingle = 'CLIENT__GET_SINGLE',
	setSelected = 'CLIENT__SET_SELECTED',
	create = 'CLIENT__CREATE',
	update = 'CLIENT__UPDATE',
	delete = 'CLIENT__DELETE',
	resetErrors = 'CLIENT__RESET_ERRORS',
	updateErrors = 'CLIENT__UPDATE_ERRORS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface idOption {
	id: number,
	option: boolean,
}

export interface Actions {
	[ActionTypes.getAll]({ commit }: AugmentedActionContext, payload: idOption): void;
	[ActionTypes.getSingle]({ commit }: AugmentedActionContext, payload: number): void;
	[ActionTypes.setSelected]({ commit }: AugmentedActionContext, payload: Client | null): void;
	[ActionTypes.create]({ commit }: AugmentedActionContext, payload: Client): void;
	[ActionTypes.update]({ commit }: AugmentedActionContext, payload: ClientUpdate): Promise<boolean>;
	[ActionTypes.delete]({ commit }: AugmentedActionContext, payload: Client): Promise<boolean>;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
	[ActionTypes.updateErrors]({ commit }: AugmentedActionContext, payload: {}): void;
}

const requiredFieldTranslated = i18n.global.t("fields.errors.required");
const addressFields = [
	{ name: 'street', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'house_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'place', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'zipcode', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'country', message: requiredFieldTranslated, isObj: false, children: null },
];

const fields: Array<ValidationItem> = [
	{ name: 'name', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'kvk_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'btw_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'iban', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'bic', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'phone_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'email', message: requiredFieldTranslated, isObj: false, children: null },

	{ name: 'billing_address', message: '', isObj: true, children: addressFields },
	{ name: 'postal_address', message: '', isObj: true, children: addressFields },
];

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getAll]({ commit }, payload) {

		try {
			const response = await api.client.getAll(payload.id);
			if (response) {
				commit(payload.option ? MutationTypes.SET_ALL_TEMP : MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.getSingle]({ commit, getters }, payload) {
		try {
			const response = await api.client.get(getters.selectedOrganization.id, payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.setSelected]({ commit }, payload) {
		commit(MutationTypes.SET_SELECTED, payload);
	},
	async [ActionTypes.create]({ commit, getters }, payload) {
		commit(MutationTypes.SET_ERRORS, {});

		const errors = checkValidity(fields, payload);

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			return false;
		}

		try {
			const response = await api.client.create(getters.selectedOrganization.id, payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
		return true;
	},
	async [ActionTypes.update]({ commit, getters }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		if (isEqual(getters.client, payload)) {
			return false;
		}

		const errors = checkValidity(fields, payload);

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			return false;
		}

		try {
			const response = await api.client.update(getters.selectedOrganization.id, payload.id, payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.delete]({ commit }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		try {
			const response = await api.client.delete(payload.organization, payload.id);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
	async [ActionTypes.updateErrors]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, payload);
	},
};

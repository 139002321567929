const ACCESS_TOKEN = "ACCESS_TOKEN";

export const jwtDecode = (t: string): Record<string, any> => {
    const [header, payload] = t.split('.');
    return {
        raw: t,
        header: JSON.parse(window.atob(header)),
        payload: JSON.parse(window.atob(payload)),
    };
}

export const setAccessToken = (token: string) => {
    localStorage.setItem(ACCESS_TOKEN, token);
}

export const getAccessToken = (): string | null => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) return null;
    const isTokenValid = Date.now() < jwtDecode(token).payload?.exp * 1000;
    if (!isTokenValid) {
        localStorage.removeItem(ACCESS_TOKEN);
    }
    return isTokenValid ? token: null;
}
import { MutationTree } from 'vuex';
import { HttpInvoice } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_ALL = 'SET_ALL_INVOICES',
	SET_SINGLE = 'SET_SINGLE_INVOICE',
	SET_ERRORS = 'SET_ERRORS_INVOICE',
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: HttpInvoice[]): void;
	[MutationTypes.SET_SINGLE](state: S, payload: HttpInvoice): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ALL](state, payload) {
		state.invoices = payload;
	},
	[MutationTypes.SET_SINGLE](state, payload) {
		state.invoice = payload;
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
};

import { onMounted, computed } from 'vue';
import { useStore } from '@/store';
import { InvoiceActions } from '@/store/modules/invoice';
import { useToast } from 'primevue/usetoast';
import { INVOICE } from '@/constants/messages';
import { ToastMessageOptions } from "primevue/toast";

export default function useInvoices() {
	const store = useStore();
	const toast = useToast();

	const getInvoices = () => {
		if (
			!store.getters.selectedOrganization ||
			store.getters.selectedOrganization.id === undefined
		) {
			return;
		}

		const organizationId = store.getters.selectedOrganization.id;

		store.dispatch(InvoiceActions.getAll, organizationId);
	};

	onMounted(getInvoices);

	const invoices = computed(() => store.getters.invoices);
	const errors = computed(() => store.getters.errors);
	if (errors.value && errors.value.detail !== undefined) {
		toast.add(INVOICE.ERROR(errors.value.detail) as ToastMessageOptions);
	}

	return {
		invoices,
		getInvoices,
	};
}

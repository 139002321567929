import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12 mt-6" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "col-6 p-0 pr-3 " }
const _hoisted_4 = {
  key: 0,
  class: "w-full"
}
const _hoisted_5 = { class: "col-12 mb-0 " }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvoiceTemplateDetailForm = _resolveComponent("InvoiceTemplateDetailForm")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_ctx.invoiceTemplate)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("h5", _hoisted_5, _toDisplayString(_ctx.title), 1),
              _createVNode(_component_InvoiceTemplateDetailForm, {
                invoiceTemplate: _ctx.storedInvoiceTemplate,
                errors: _ctx.errors,
                "onUpdate:invoiceTemplate": _ctx.updateInvoiceInputFields
              }, null, 8, ["invoiceTemplate", "errors", "onUpdate:invoiceTemplate"]),
              (_ctx.errors['detail'])
                ? (_openBlock(), _createBlock("small", _hoisted_6, _toDisplayString(_ctx.errors['detail']), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
import { InjectionKey } from 'vue';
import { createStore, createLogger, Store as VuexStore, useStore as baseUseStore } from 'vuex';
import client, { Store as ClientStore } from './modules/client';
import invoice, { Store as InvoiceStore } from './modules/invoice';
import invoiceTemplate, { Store as InvoiceTemplateStore } from './modules/invoiceTemplate';
import organization, { Store as OrganizationStore } from './modules/organization';
import notification, { Store as NotificationStore } from './modules/notification';
import customField, { Store as CustomFieldStore } from './modules/customField'
import user, { Store as UserStore } from './modules/user';
import { RootState } from './state';
import VuexPersister from '@/utils/persister';

const vuexPersister = new VuexPersister({
	key: 'vuex_persist',
	overwrite: true,
});

export type RootStore = ClientStore<Pick<RootState, 'client'>>
	& InvoiceStore<Pick<RootState, 'invoice'>>
	& InvoiceTemplateStore<Pick<RootState, 'invoiceTemplate'>>
	& UserStore<Pick<RootState, 'user'>>
	& NotificationStore<Pick<RootState, 'notification'>>
	& OrganizationStore<Pick<RootState, 'organization'>>
	& CustomFieldStore<Pick<RootState, 'customField'>>

// define injection key
// eslint-disable-next-line
export const key: InjectionKey<VuexStore<RootState>> = Symbol();
const store = createStore<RootState>({
	plugins: [
		createLogger(),
		vuexPersister.persist
	],
	modules: {
		client,
		invoice,
		invoiceTemplate,
		user,
		organization,
		notification,
		customField
	},
});

export function useStore(): RootStore {
	return baseUseStore(key);
}

export default store;


import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DataTable, Column, Tag, Card, Tooltip } from '@/plugins/PrimeVue';
import ConfirmInvoice from '@/components/ConfirmInvoice/ConfirmInvoice.vue';
import ConfirmDialog from '@/components/ConfirmDialog/ConfirmDialog.vue';
import { dataTableFilter } from "@/utils/dataTableFilter";
import { ReminderInfo } from '@/interfaces';
import draggable from 'vuedraggable';

import useInvoices from '@/composable/useInvoices';
import useInvoiceConfirmations from '@/composable/useInvoiceConfirmations';
import { useI18n } from 'vue-i18n';
import moment from "moment";
import useOrganizations from "@/composable/useOrganizations";

export default defineComponent({
	components: {
		DataTable,
		Column,
		Tag,
		Card,
		ConfirmInvoice,
		ConfirmDialog,
		draggable
	},
	directives: {
		tooltip: Tooltip,
	},
	setup: function () {
		const router = useRouter();
		const { invoices, getInvoices } = useInvoices();
		const { confirmSend, confirmPaid } = useInvoiceConfirmations();
		const filter = ref(dataTableFilter());
		const loading = ref(false);
		const statuses = [
			'confirmed',
			'unconfirmed',
			'sent',
			'paid',
		];
		const { locale } = useI18n();
		const { selectedOrganization } = useOrganizations();

		watch(selectedOrganization, () => {
			getInvoices();
		});

		const dateFormat = (date: string) => {
			if (date) {
				return moment(String(date)).format('DD-MM-YYYY');
			}
		};

		const toNew = () => {
			router.push({name: 'invoice-create'});
		};

		const toEdit = (id: number) => {
			router.push({name: 'invoice-edit', params: {id}});
		};

		const toPreview = (id: number, name: string) => {
			router.push({name: 'invoice-preview', params: {id}});
		}

		const toCopyInvoice = (id: number) => {
			router.push({name: 'invoice-create', query: {template: id}});
		};

		const getButtonColor = (reminderInfo: (ReminderInfo | null)) => {
			if (!reminderInfo) return 'warning';
			return 'danger';
		};

		const clearFilter = () => {
			initFilters();
		};
		const initFilters = () => {
			filter.value = dataTableFilter();
		};

		const confirmSendFunc = async (data: any) => {
			await confirmSend(data);
			getInvoices();
		}

		const confirmPaidFunc = async (data: any) => {
			await confirmPaid(data);
			getInvoices();
		}

		return {
			invoices,
			toNew,
			toEdit,
			toPreview,
			draggable,
			toCopyInvoice,
			getButtonColor,
			confirmSendFunc,
			confirmPaidFunc,
			initFilters,
			clearFilter,
			filter,
			dateFormat,
			loading,
			statuses,
			locale
		};
	},
	methods: {
		getTagTitle: (reminderInfo: (ReminderInfo | null)) => {
			if (!reminderInfo) return 'Niet betaald';

			if (reminderInfo.currentStage === reminderInfo.maximumStage) return 'Incasso';

			return `Herinnering ${reminderInfo.currentStage}/${reminderInfo.maximumStage}`;
		},
	},
});

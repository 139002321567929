import { ActionContext, ActionTree } from 'vuex';
import { isEqual } from 'lodash';
import { api } from '@/api';
import { RootState } from '@/store/state';
import { HttpNotification, NotificationUpdate } from '@/interfaces';
import { createError } from '@/utils/createError';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';

export enum ActionTypes {
	getAll = 'NOTIFICATION__GET_ALL',
	getSingle = 'NOTIFICATION__GET_SINGLE',
	create = 'NOTIFICATION__CREATE',
	update = 'NOTIFICATION__UPDATE',
	delete = 'NOTIFICATION__DELETE',
	resetErrors = 'NOTIFICATION__RESET_ERRORS',
	updateErrors = 'NOTIFICATION__UPDATE_ERRORS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
	[ActionTypes.getAll]({ commit }: AugmentedActionContext): void;
	[ActionTypes.getSingle]({ commit }: AugmentedActionContext, payload: number): void;
	[ActionTypes.create]({ commit }: AugmentedActionContext, payload: HttpNotification): void;
	[ActionTypes.update]({ commit }: AugmentedActionContext, payload: NotificationUpdate): Promise<boolean>;
	[ActionTypes.delete]({ commit }: AugmentedActionContext, payload: number): Promise<boolean>;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
	[ActionTypes.updateErrors]({ commit }: AugmentedActionContext, payload: {}): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getAll]({ commit }) {
		try {
			const response = await api.notification.getAll();
			if (response) {
				commit(MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.getSingle]({ commit }, payload) {
		try {
			const response = await api.notification.get(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.create]({ commit }, payload) {
		try {
			const response = await api.notification.create(payload);
			if (response) {
				commit(MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.update]({ commit, getters }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		if (isEqual(getters.notification(payload.id), payload)) {
			return false;
		}

		try {
			const response = await api.notification.update(payload.id, payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.delete]({ commit }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		try {
			const response = await api.notification.delete(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
	async [ActionTypes.updateErrors]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, payload);
	},
};


import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, PButton } from '@/plugins/PrimeVue';
import OrganizationForm from '@/components/OrganizationForm/OrganizationForm.vue';
import useOrganization from '@/composable/useOrganization';

export default defineComponent({
	components: {
		Panel,
		OrganizationForm,
		PButton,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const title = ref(route.meta.title);
		const organizationId = +route.params.id || 0;
		const saveButtonLabel = !organizationId ? 'Aanmaken' : 'Aanpassen';

		const {
			organization,
			saveOrganization,
			updateOrganization,
			updateErrors,
			errors,
		} = useOrganization(true, organizationId);

		const submit = async () => {
			const success = await saveOrganization();
			if (success) {
				await router.push({ name: 'organizations' });
			}
		};

		const back = () => {
			router.push({ name: 'organizations' });
		};

		return {
			title,
			organization,
			back,
			saveButtonLabel,
			submit,
			errors,
			updateOrganization,
			updateErrors
		};
	},
});

import { MutationTree } from 'vuex';
import { Client } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_ALL = 'SET_ALL_CLIENTS',
	SET_ALL_TEMP = 'SET_ALL_TEMP_CLIENTS',
	SET_SINGLE = 'SET_SINGLE_CLIENT',
	SET_ERRORS = 'SET_ERRORS_CLIENT',
	SET_SELECTED = 'SET_SELECTED_CLIENT'
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: Client[]): void;
	[MutationTypes.SET_ALL_TEMP](state: S, payload: Client[]): void;
	[MutationTypes.SET_SINGLE](state: S, payload: Client): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
	[MutationTypes.SET_SELECTED](state: S, payload: Client | null): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ALL](state, payload) {
		state.clients = payload;
	},
	[MutationTypes.SET_ALL_TEMP](state, payload) {
		state.tempClients = payload;
	},
	[MutationTypes.SET_SINGLE](state, payload) {
		state.client = payload;
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
	[MutationTypes.SET_SELECTED](state, payload) {
		if (payload !== null) {
			state.selectedClient = payload;
			return;
		}

		state.selectedClient = {} as Client;
	},
};

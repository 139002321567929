import { ref, computed, onMounted, watch, Ref } from 'vue';
import { cloneDeep } from 'lodash';
import { useToast } from 'primevue/usetoast';
import { useStore } from '@/store';
import { HttpInvoice, InvoiceTemplate } from '@/interfaces';
import { InvoiceTemplateActions } from '@/store/modules/invoiceTemplate';
import { InvoiceActions } from '@/store/modules/invoice';
import { INVOICE } from '@/constants/messages';
import { ToastMessageOptions } from 'primevue/toast';
import useNotifications from '../useNotifications';

export default function useInvoice(watchStore: boolean = true, invoiceId: number = 0, organizationIdOverride: number = 0) {
	const store = useStore();
	const toast = useToast();

	const storedInvoice = computed(() => store.getters.invoice);
	const invoice: Ref<HttpInvoice> = ref({paymentReminders: [30, 7, 7]} as HttpInvoice);
	const errors = computed(() => store.getters.invoiceErrors);
	const { createNotification } = useNotifications();

	const setInvoice = (existingInvoice: HttpInvoice) => {
		invoice.value = cloneDeep(existingInvoice);

		if (!invoice.value.id && invoiceId) {
			invoice.value.id = invoiceId;
		}
	};

	const updateInvoice = (newInvoice: HttpInvoice) => {
		invoice.value = newInvoice;
	};

	const saveInvoice = async () => {
		const dispatchMethod = invoice.value.id > 0 ? InvoiceActions.update : InvoiceActions.create;

		store.dispatch(InvoiceActions.resetErrors);

		const success = await store.dispatch(dispatchMethod, invoice.value);

		if (!success) {
			if (errors.value && Object.keys(errors.value).length) {
				if (errors.value && errors.value.detail !== undefined) {
					toast.add(INVOICE.ERROR(errors.value.detail) as ToastMessageOptions);
				}
			}
			return false;
		}

		if(dispatchMethod === InvoiceActions.create) {
			let tempInvoiceTemplate: InvoiceTemplate = {
				id: 0,
				name: '',
				reminder_mail: '',
				inputs: [],
				reminder_messages: [],
			};

			if (invoice.value.invoiceTemplateId) {
				tempInvoiceTemplate = store.getters.invoiceTemplate;
			} else {
				tempInvoiceTemplate.name = 'Onbekend';
			}

			const notificationCreated = createNotification({
				title: 'Factuur aangemaakt.',
				description: `Factuur '${invoice.value.name}' is aangemaakt met sjabloon '${tempInvoiceTemplate.name}'.`,
				severity: 2,
			});

			if (notificationCreated) {
				toast.add(INVOICE.CREATED(invoice.value.name) as ToastMessageOptions);
			} else {
				return false;
			}
		}

		return true;
	};

	const updateErrors = (newInvoice: HttpInvoice) => {
		const inputErrors = errors.value;
		if (newInvoice.inputs) {
			for (let i = 0; i < newInvoice.inputs.length; i += 1) {
				const prop = newInvoice.inputs[i];
				if (prop.items !== undefined && prop.items !== null && prop.items !== []) {
					delete inputErrors[prop.id];
				}
			}
		}
		store.dispatch(InvoiceTemplateActions.updateErrors, inputErrors);
	};

	watch(storedInvoice, setInvoice);

	const getInvoiceById = (id: number, resetErr: boolean, organizationIdOverride: number = 0) => {
		store.dispatch(InvoiceActions.getSingle, {id: id, organizationId: organizationIdOverride});

		if (resetErr) store.dispatch(InvoiceActions.resetErrors);
	}

	if ( watchStore ) {
		watch(storedInvoice, updateInvoice);
	}

	if ( invoiceId > 0 ) {
		onMounted(() => {
			getInvoiceById(invoiceId, false, organizationIdOverride);
		});
	}

	return {
		invoice,
		storedInvoice,
		getInvoiceById,
		updateInvoice,
		saveInvoice,
		updateErrors,
		errors,
	};
}

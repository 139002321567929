import { onMounted, computed, watch } from 'vue';
import { useStore } from '@/store';
import { ClientActions } from '@/store/modules/client';
import { useToast } from 'primevue/usetoast';
import { CLIENT } from '@/constants/messages';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { ToastMessageOptions } from "primevue/toast";

export default function useClients() {
	const store = useStore();
	const toast = useToast();

	const clients = computed(() => store.getters.clients);
	const tempClients = computed(() => store.getters.tempClients);
	const selectedClient = computed(() => store.getters.selectedClient);
	const errors = computed(() => store.getters.errors);

	const getClients = (organizationId: number = 0, saveToTemp: boolean = false) => {
		let usedId = organizationId;

		if (usedId === 0) {
			if (
				!store.getters.selectedOrganization ||
				store.getters.selectedOrganization.id === undefined
			) return;

			usedId = store.getters.selectedOrganization.id;
		}

		if (usedId !== undefined) {
			store.dispatch(ClientActions.getAll, { id: usedId, option: saveToTemp });
		}
	};

	onMounted(getClients);

	if (errors.value && errors.value.detail !== undefined) {
		toast.add(CLIENT.ERROR(errors.value.detail) as ToastMessageOptions);
	}

	return {
		errors,
		clients,
		tempClients,
		getClients,
		selectedClient
	};
}

import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "col p-0 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", null, _toDisplayString(_ctx.input.name) + ":", 1),
    _createVNode("div", _hoisted_2, [
      (_ctx.input.type === 'textarea')
        ? (_openBlock(), _createBlock(_component_Textarea, {
            key: 0,
            id: _ctx.input.key,
            class: "whitespace-pre-wrap disabled-cursor bg-black-alpha-10",
            style: {"width":"50%"},
            modelValue: _ctx.input.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.input.value = $event)),
            tabindex: 0,
            rows: "3",
            disabled: "disabled",
            "data-testid": _ctx.input.name
          }, null, 8, ["id", "modelValue", "data-testid"]))
        : (_ctx.input.type === 'number')
          ? (_openBlock(), _createBlock(_component_InputNumber, {
              key: 1,
              id: _ctx.input.key,
              class: "whitespace-pre-wrap disabled-cursor bg-black-alpha-10 py-0",
              style: {"width":"50%"},
              modelValue: _ctx.input.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.input.value = $event)),
              tabindex: 0,
              rows: "3",
              disabled: "disabled",
              "data-testid": _ctx.input.name
            }, null, 8, ["id", "modelValue", "data-testid"]))
          : (_openBlock(), _createBlock(_component_InputText, {
              key: 2,
              id: _ctx.input.id,
              modelValue: _ctx.input.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.input.value = $event)),
              style: {"width":"50%"},
              tabindex: 0,
              disabled: "disabled",
              class: "disabled-cursor bg-black-alpha-10",
              "data-testid": _ctx.input.name
            }, null, 8, ["id", "modelValue", "data-testid"])),
      (_ctx.input.id != 'specification')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 3,
            class: "p-button-rounded p-button-danger p-button-outlined",
            style: {"width":"20px","height":"20px","padding":"10px","margin":"10px 0 10px 10px"},
            icon: "pi pi-times",
            tabindex: 0,
            "data-testid": `${_ctx.input.name}-remove`,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('removeInput', _ctx.input)))
          }, null, 8, ["data-testid"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
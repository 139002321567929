import { MutationTree } from 'vuex';
import { Notification } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_ALL = 'SET_ALL_NOTIFICATIONS',
	SET_SINGLE = 'SET_SINGLE_NOTIFICATION',
	SET_ERRORS = 'SET_ERRORS_NOTIFICATION',
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: Notification[]): void;
	[MutationTypes.SET_SINGLE](state: S, payload: Notification): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ALL](state, payload) {
		state.notifications = payload;
	},
	[MutationTypes.SET_SINGLE](state, payload) {
		state.notifications = state.notifications.map((notification: Notification) => {
			if (notification.id !== payload.id) {
				return notification;
			}
			return payload;
		});
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
};

// Template params are identified by: $${Parameter}
// Example: $${Name} will be replaced by the value of the Name parameter
export default class DynamicTemplateHelper {
	static replaceTemplate(template: string | undefined, params: Record<string, string>): string {
		let result = template || "";

		for ( let key in params ) {
			result = result.replace(
				new RegExp( "\\$\\$\\{" + key + "\\}", "g"),
				params[key]
			);
		}

		return result;
	}
}

import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InvoicePreviewerVue = _resolveComponent("InvoicePreviewerVue")!
  const _component_PButton = _resolveComponent("PButton")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode(_component_Button, {
      label: "Niet bevestigd",
      class: "p-button-rounded p-button p-button-warning",
      role: "button",
      onClick: _ctx.openConfirmation
    }, null, 8, ["onClick"]), [
      [
        _directive_tooltip,
        'Bevestig',
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('actions.confirm'),
      visible: _ctx.isDisplayOpen,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDisplayOpen = $event)),
      style: {width: '900px'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_PButton, {
          label: _ctx.$t('actions.cancel'),
          icon: "pi pi-times",
          onClick: _ctx.closeConfirmation,
          class: "p-button-text"
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_PButton, {
          label: _ctx.$t('actions.confirm'),
          icon: "pi pi-check",
          onClick: _ctx.confirmAndClose,
          class: "p-button-text",
          autofocus: "",
          "data-testid": "confirm"
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode("div", {
          class: _ctx.$style.content
        }, [
          _createVNode(_component_InvoicePreviewerVue, {
            invoiceId: _ctx.invoice.id,
            hasDownload: false
          }, null, 8, ["invoiceId"])
        ], 2)
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ]))
}
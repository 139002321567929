import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "p-d-flex p-jc-between p-ai-center" }
const _hoisted_5 = { class: "p-input-icon-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("h5", null, _toDisplayString(_ctx.$t('pages.invoiceTemplates.title')), 1),
        _createVNode(_component_DataTable, {
          value: _ctx.invoiceTemplates,
          paginator: true,
          rowHover: true,
          rows: 10,
          key: _ctx.invoiceTemplates,
          rowsPerPageOptions: [10,20,50],
          paginatorTemplate: "\n\t\t\t\t\t\tCurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown\n\t\t\t\t\t",
          showGridlines: "",
          currentPageReportTemplate: "{first} tot {last} van {totalRecords}"
        }, {
          header: _withCtx(() => [
            _createVNode("div", _hoisted_4, [
              _createVNode("span", _hoisted_5, [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Maak een nieuw sjabloon",
                  class: "p-button-rounded",
                  "data-testid": "invoice-template-create",
                  onClick: _ctx.toNew
                }, null, 8, ["onClick"])
              ])
            ])
          ]),
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('pages.invoiceTemplates.noInvoiceTemplatesFound')), 1)
          ]),
          loading: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('pages.invoiceTemplates.loadingInvoiceTemplates')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "name",
              header: "Naam"
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "fieldAmount",
              header: "Velden aantal"
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.inputs.length) + " " + _toDisplayString(`${data.inputs.length === 1 ? 'veld' : 'velden' }`), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "Acties",
              headerStyle: "width: 11rem;"
            }, {
              body: _withCtx(({data}) => [
                _withDirectives(_createVNode(_component_Button, {
                  "data-testid": `${data.name}-view`,
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-rounded p-button-outlined mr-2",
                  onClick: ($event: any) => (_ctx.toView(data.id))
                }, null, 8, ["data-testid", "onClick"]), [
                  [
                    _directive_tooltip,
                    'Bekijken',
                    void 0,
                    { top: true }
                  ]
                ]),
                _withDirectives(_createVNode(_component_Button, {
                  "data-testid": `${data.name}-edit`,
                  type: "button",
                  icon: "pi pi-pencil",
                  class: "p-button-rounded p-button-outlined mr-2",
                  onClick: ($event: any) => (_ctx.toEdit(data.id))
                }, null, 8, ["data-testid", "onClick"]), [
                  [
                    _directive_tooltip,
                    'Aanpassen',
                    void 0,
                    { top: true }
                  ]
                ]),
                _withDirectives(_createVNode(_component_Button, {
                  "data-testid": `${data.name}-delete`,
                  type: "button",
                  icon: "pi pi-trash",
                  class: "p-button-rounded p-button-outlined p-button-danger",
                  onClick: ($event: any) => (_ctx.toDelete(data))
                }, null, 8, ["data-testid", "onClick"]), [
                  [
                    _directive_tooltip,
                    'Verwijder',
                    void 0,
                    { top: true }
                  ]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ])
  ]))
}

import { defineComponent, ref, watch, Ref } from 'vue';
import { HttpInvoice } from '@/interfaces';
import { InputText, Textarea, InputNumber } from '@/plugins/PrimeVue';
import InvoiceListProp, { InvoicePaymentItem } from '@/components/InvoiceForm/InvoiceListProp/InvoiceListProp.vue';
import SetInvoiceReminder from "@/components/Widgets/SetInvoiceReminder.vue";
import moment from "moment/moment";
import { useRoute } from "vue-router";

export default defineComponent({
	components: {
		InputText,
		Textarea,
		InputNumber,
		InvoiceListProp,
		SetInvoiceReminder
	},
	props: {
		data: {
			type: Object as () => HttpInvoice,
			default: {},
			required: true,
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		preview: {
			type: Boolean,
			default: false,
			required: false,
		},

	},
	setup(props, { emit }) {
		interface TempPropList {
			description: string,
			name: string,
			items: InvoicePaymentItem[],
		}

		const route = useRoute();
		const invoice = ref({ ...props.data });
		const invoiceId = +route.params.id || 0;
		const paymentTerms: Ref<number[]> = ref([]);
		const invoicePropertyList: Ref<TempPropList> = ref({
			description: "",
			name: "",
			items: [],
		});
		const dateToday = moment().format('YYYY-MM-DD');

		if (invoiceId !== 0) {
			paymentTerms.value = invoice.value.paymentReminders;
			invoicePropertyList.value = {
				name: invoice.value.name,
				description: 'products',
				items: invoice.value.propertyList
			};
		} else {
			paymentTerms.value = invoice.value.paymentReminders;
			invoicePropertyList.value = { name: invoice.value.name, description: 'products', items: [] };
		}

		const updatePropertyList = (index: number, property: any) => {
			invoice.value.propertyList = property.items;
		};

		const updatePaymentTerms = (property: any) => {
			invoice.value.paymentReminders = property;
		}

		watch(invoice.value, () => {
			emit('update:errors', invoice.value);
			emit('update:invoice', invoice.value);
		});

		watch(() => props.data, () => {
			invoice.value = props.data;
			paymentTerms.value = props.data.paymentReminders;
			invoicePropertyList.value.items = invoice.value.propertyList;

			emit('update:invoice', invoice.value);
		});

		return {
			updatePropertyList,
			updatePaymentTerms,
			paymentTerms,
			invoice,
			dateToday,
			invoicePropertyList
		};
	},
});

import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/state';
import { api } from '@/api';
import { msalLogin, msalGetToken, msalLogout } from '@/authproviders/msal';
import { createError } from '@/utils/createError';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import { getAccessToken, jwtDecode, setAccessToken } from '@/utils/accessToken';

export enum ActionTypes {
	localLogin = 'USER__LOCAL_LOGIN',
	msalLogin = 'USER__MSAL_LOGIN',
	logout = 'USER__LOGOUT',
	resetErrors = 'USER__RESET_ERRORS',
	getAll = 'USER__GET__ALL',
	setAll = 'USER__SET_ALL'
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

interface LocalLogin {
	username: string;
	password: string;
}

export interface Actions {
	[ActionTypes.localLogin]({ commit }: AugmentedActionContext, payload: LocalLogin): void;
	[ActionTypes.msalLogin]({ commit, dispatch }: AugmentedActionContext): void;
	[ActionTypes.logout]({ commit }: AugmentedActionContext): void;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
	[ActionTypes.getAll]({ commit }: AugmentedActionContext): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.localLogin]({ commit }, payload) {
		try {
			const response = await api.user.localLogin(payload);
			if (!response) return false;

			const { payload: jwtData } = jwtDecode(response.data.access_token);
			commit(MutationTypes.SET_USER, { name: jwtData?.username || '', username: jwtData?.email || '' });
			setAccessToken(response.data.access_token);
			commit(MutationTypes.SET_ERRORS, {});
			return true;
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.msalLogin]({ commit, dispatch }) {
		try {
			const account = await msalLogin();
			if (!account) {
				commit(MutationTypes.SET_ERRORS, { detail: 'account failure' });
			}
			// dispatch(ActionTypes.getToken, { account });
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, { detail: 'something went wrong...' });
		}
	},
	async [ActionTypes.logout]({ commit }) {
		try {
			commit(MutationTypes.RESET_USER, undefined);
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.getAll]({ commit }) {
		try {
			const response = await api.user.getAll();
			if (response) {
				commit(MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
};

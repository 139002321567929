import { CustomField } from "@/interfaces";
import { State } from "./state";
import { GetterTree } from "vuex";
import { RootState } from "@/store/state";

export type Getters<S = State> = {
	customFields(state: S): CustomField[];
	customFieldErrors(state: S): Record<string, string>;
}

export const getters: GetterTree<State, RootState> & Getters = {
	customFields: state => state.customFields,
	customFieldErrors: state => state.errors
}

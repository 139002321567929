
import { defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, PButton } from '@/plugins/PrimeVue';
import InvoiceTemplateDetail from '@/components/InvoiceTemplateDetail/InvoiceTemplateDetail.vue'
import useInvoiceTemplate from '@/composable/useInvoiceTemplate';
import InvoiceDetail from "@/views/InvoiceTemplate/Preview/InvoiceTemplatePreviewer.vue"

export default defineComponent({
	components: {
		Panel,
		InvoiceTemplateDetail,
		PButton,
		InvoiceDetail
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const title = ref(route.meta.title);
		const invoiceTemplateId = +route.params.id || 0

		const {
			invoiceTemplate,
			errors,
		} = useInvoiceTemplate(true, invoiceTemplateId);

		const back = () => {
			router.push({ name: 'invoice-template' });
		};

		const toEdit = () => {
			router.push({ name: 'invoice-template-edit', params: { invoiceTemplateId }});
		};

		return {
			title,
			invoiceTemplate,
			invoiceTemplateId,
			back,
			toEdit,
			errors,
		};
	},
});

import { Client } from "@/interfaces";

export interface ValidationItem {
	name: string,
	message: string,
	isObj: boolean,
	children: Array<ValidationItem> | null
}

export const checkValidity = (
	checkItems: Array<ValidationItem>,
	payload: Record<string, any>,
	prefix: string = ''
) => {
	let errors: Record<string, string> = {};

	// TODO refactor to use Array.prototype.reduce
	for (let i = 0, l = checkItems.length; i < l; ++i) {
		const checkItem = checkItems[i];
		if (checkItem.isObj && checkItem.children) {
			errors = {
				...errors,
				...checkValidity(checkItem.children, payload, checkItem.name)
			};
			continue;
		}


		if (prefix !== '') {
			if (!payload[prefix] || !payload[prefix][checkItem.name]) {
				errors[prefix + '-' + checkItem.name] = checkItem.message;
			}

			continue;
		}

		if (!payload[checkItem.name]) {
			errors[checkItem.name] = checkItem.message;
		}
	}

	return errors;
}

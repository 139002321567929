import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ml-3 mb-4 border-bottom-1 border-secondary" }
const _hoisted_2 = { class: "ml-3 mb-4 flex flex-row justify-center border-bottom-1 border-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('pages.invoiceTemplates.templateName')) + ": " + _toDisplayString(_ctx.internalInvoiceTemplate.name), 1),
    _createVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('general.client')) + ": " + _toDisplayString(_ctx.client.name), 1),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.internalInvoiceTemplate, (invoiceTemplate, index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: "flex flex-column ml-3"
      }, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(invoiceTemplate, (input, index) => {
          return (_openBlock(), _createBlock("div", {
            key: index,
            class: "flex flex-column"
          }, [
            (input.name)
              ? (_openBlock(), _createBlock(_component_InputText, {
                  key: 0,
                  disabled: "disabled",
                  modelValue: input.name,
                  "onUpdate:modelValue": ($event: any) => (input.name = $event),
                  id: input.id,
                  class: "mb-4 disabled-cursor bg-black-alpha-10"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "id"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    }), 128))
  ], 64))
}
import { CUSTOM_FIELD } from "@/constants/messages";
import { CustomField } from "@/interfaces";
import { useStore } from "@/store";
import { CustomFieldActions } from '@/store/modules/customField'
import { cloneDeep } from "lodash";
import { useToast } from "primevue/usetoast";
import { computed, onMounted, ref } from "vue";

export interface HttpCustomField {
	id?: number,
	key: string,
	type: string,
	organization: number
}

export default function useCustomField() {
	const store = useStore();
	const customField = ref({} as HttpCustomField);

	const saveCustomField = async () => {
		store.dispatch(CustomFieldActions.resetErrors);

		store.dispatch(CustomFieldActions.create, customField.value);
	}

	return {
		customField,
		saveCustomField,
	}
}

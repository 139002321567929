
import { defineComponent, ref, watch } from 'vue';
import AppSubmenu from "./AppSubmenu.vue";
import useOrganizations from '@/composable/useOrganizations';
import useOrganization from '@/composable/useOrganization';
import useClients from '@/composable/useClients';
import { Dropdown } from '@/plugins/PrimeVue';
import { useRouter } from "vue-router";

export default defineComponent({
	name: "AppMenu",
	emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
	props: {
		model: Array,
		layoutMode: String,
		active: Boolean,
		mobileMenuActive: Boolean,
	},
	components: {
		Dropdown,
		AppSubmenu
	},
	setup() {
		const { organizations, selectedOrganization } = useOrganizations();
		const router = useRouter();
		const { setSelectedOrganization } = useOrganization();
		const { getClients } = useClients();
		const watchedOrganizations = ref();
		const currentOrganization = ref(selectedOrganization.value);

		watch(selectedOrganization, () => {
			currentOrganization.value = selectedOrganization.value;
		});

		watch(organizations, () => {
			if (!selectedOrganization.value.id && organizations.value.length > 0) {
				// Can be removed if state is persisted
				router.push({ name: 'organizations' });

				setSelectedOrganization(organizations.value[0]);
			}
		});

		const setCurrentOrganization = (event: Event) => {
			// eslint-disable-next-line
			// @ts-ignore
			const eventValue = (event.value);
			setSelectedOrganization(eventValue);
			getClients();
		}

		return {
			organizations,
			currentOrganization,
			setCurrentOrganization,
			watchedOrganizations,
		};
	},
	methods: {
		onMenuClick(event: Event) {
			this.$emit("menu-click", event);
		},
		onMenuItemClick(event: Event) {
			this.$emit("menuitem-click", event);
		},
		onRootMenuItemClick(event: Event) {
			this.$emit("root-menuitem-click", event);
		},
	},
});

import { computed } from 'vue';
import { InvoicePropertyItem, PropertyList } from '@/interfaces';

export default function useInvoicePropertyList(property: PropertyList) {
	const calculateVatValue = (vatValue: number) => {
		if (!property.items) return 0;

		const vatItemsByVatValue = property.items.filter(item => (+item.vat) == vatValue);

		const totalValue = vatItemsByVatValue.reduce(
			(accumulator: number, item: InvoicePropertyItem): number => accumulator + item.price, 0,
		)

		return totalValue * (vatValue / 100)
	};

	const calculateTotalWithAddedVat = () => {
		if (!property.items) return 0;

		return property.items.reduce(
			(accumulator: number, item: InvoicePropertyItem): number => accumulator + (item.price * (1 + ((+item.vat) / 100))), 0,
		);
	}

	const vat0 = computed(() => ({
		description: '0% BTW',
		price: calculateVatValue(0),
	}));

	const vat9 = computed(() => ({
		description: '9% BTW',
		price: calculateVatValue(9),
	}));

	const vat21 = computed(() => ({
		description: '21% BTW',
		price: calculateVatValue(21),
	}));

	const totalWithVat = computed(() => ({
		description: 'Totaal',
		price: calculateTotalWithAddedVat(),
	}));

	const calculatedItems = computed(() => [
		vat0.value,
		vat9.value,
		vat21.value,
		totalWithVat.value
	]);

	return {
		calculatedItems,
	};
}


import { defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, Button, Card } from '@/plugins/PrimeVue';
import InvoiceTemplateDetailForm from '@/components/InvoiceTemplateDetail/InvoiceTemplateDetailForm.vue';
import InvoiceForm from '@/components/InvoiceForm/InvoiceForm.vue';
import ClientForm from '@/components/ClientForm/ClientForm.vue';
import FormFields from "@/components/Widgets/Draggable/FormFields.vue";
import useInvoiceTemplate from '@/composable/useInvoiceTemplate';
import useInvoice from "@/composable/useInvoice";
import { Dropdown } from '@/plugins/PrimeVue';
import useClients from '@/composable/useClients';
import useOrganizations from '@/composable/useOrganizations';
import moment from "moment/moment";

export default defineComponent({
	components: {
		Panel,
		Button,
		InvoiceTemplateDetailForm,
		InvoiceForm,
		ClientForm,
		Card,
		FormFields,
		Dropdown
	},
	setup() {
		moment.locale();
		const route = useRoute();
		const router = useRouter();
		const { organizations } = useOrganizations();
		const { clients } = useClients();
		const title = ref(route.meta.title);
		const invoiceTemplateId = ref(parseInt(`${route.params.id || 0}`));

		const {
			invoiceTemplate,
			storedInvoiceTemplate,
			updateInvoiceTemplate,
			saveInvoiceTemplate,
			updateErrors,
			errors,
		} = useInvoiceTemplate(true, invoiceTemplateId.value);

		const back = () => {
			router.push({ name: 'invoice-template' });
		};

		const submit = async () => {
			const success = await saveInvoiceTemplate();

			if (success) {
				await router.push({name: 'invoices'});
			}
		};

		return {
			title,
			invoiceTemplate,
			storedInvoiceTemplate,
			updateInvoiceTemplate,
			submit,
			back,
			errors,
			updateErrors,
			organizations,
			clients,
		};
	},
});

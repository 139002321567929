import { ApiInstance } from '@/plugins/Axios';
import { User } from '@/interfaces';

const usersApiUrl = '/api/v1/users/';

interface LocalLogin {
	username: string;
	password: string;
}

interface AccessToken {
	access_token: string;
}

export const UserService = (api: ApiInstance) => ({
	async localLogin(data: LocalLogin) {
		return api.post<AccessToken>('/api/auth/login/', data);
	},
	async getAll() {
		return api.get<User[]>(`${usersApiUrl}`);
	},
	async get(id: number) {
		return api.get<User>(`${usersApiUrl}${id}`);
	},
});

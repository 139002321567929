import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "mr-3 w-full" }
const _hoisted_3 = { class: "mr-3 w-full" }
const _hoisted_4 = { class: "flex w-full align-items-center" }
const _hoisted_5 = {
  style: {"margin-left":"8px"},
  for: "use-mailbox"
}
const _hoisted_6 = { class: "mr-3 w-full" }
const _hoisted_7 = { class: "mr-3 w-full" }
const _hoisted_8 = { class: "flex w-full align-items-center" }
const _hoisted_9 = {
  style: {"margin-left":"8px"},
  for: "use-mailbox"
}
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_PButton = _resolveComponent("PButton")!

  return (_openBlock(), _createBlock("div", null, [
    (!_ctx.readOnly)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_InputText, {
              modelValue: _ctx.newItem.description,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.newItem.description = $event)),
              class: _ctx.$style.input,
              id: "item-name",
              placeholder: _ctx.$t('general.description')
            }, null, 8, ["modelValue", "class", "placeholder"])
          ]),
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_InputText, {
              modelValue: _ctx.newItem.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.newItem.email = $event)),
              class: _ctx.$style.input,
              id: "item-email",
              placeholder: "email@email.nl"
            }, null, 8, ["modelValue", "class"])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_CheckBox, {
              modelValue: _ctx.newItem.isReminderPoint,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.newItem.isReminderPoint = $event)),
              name: "reminder_point",
              binary: true
            }, null, 8, ["modelValue"]),
            _createVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('general.reminderPoint')), 1)
          ]),
          _createVNode("div", null, [
            _createVNode(_component_PButton, {
              icon: "pi pi-plus",
              class: "p-button-rounded p-button-success",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addNewItem()))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.clientRef.contacts, (item, index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: "flex mt-4"
      }, [
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_InputText, {
            disabled: _ctx.readOnly,
            modelValue: item.description,
            "onUpdate:modelValue": ($event: any) => (item.description = $event),
            class: _ctx.$style.input,
            placeholder: _ctx.$t('general.description')
          }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "class", "placeholder"])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_InputText, {
            disabled: _ctx.readOnly,
            modelValue: item.email,
            "onUpdate:modelValue": ($event: any) => (item.email = $event),
            class: _ctx.$style.input,
            placeholder: "email@email.nl"
          }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "class"])
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_CheckBox, {
            disabled: _ctx.readOnly,
            modelValue: item.isReminderPoint,
            "onUpdate:modelValue": ($event: any) => (item.isReminderPoint = $event),
            name: "reminder_point",
            binary: true
          }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue"]),
          _createVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('general.reminderPoint')), 1)
        ]),
        (!_ctx.readOnly)
          ? (_openBlock(), _createBlock("div", _hoisted_10, [
              _createVNode(_component_PButton, {
                icon: "pi\n\t\t\t\t\t\tpi-minus",
                class: "p-button-rounded p-button-danger",
                onClick: ($event: any) => (_ctx.removeItem(item))
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
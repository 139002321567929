import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "formgrid" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = { class: "block col-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("p", null, _toDisplayString(_ctx.$t('pages.invoices.setReminder')) + ":", 1),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.paymentTerms, (term, index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: "flex py-1 mr-5"
      }, [
        _createVNode("div", {
          class: "block col-2 m-0 px-0",
          style: {"min-width":"125px"},
          for: term
        }, _toDisplayString(_ctx.$tc('pages.invoices.remindAfter', parseInt(index) + 1)) + ": ", 9, ["for"]),
        _createVNode(_component_InputNumber, {
          id: `payment_term${index}`,
          modelValue: _ctx.paymentTerms[index],
          "onUpdate:modelValue": ($event: any) => (_ctx.paymentTerms[index] = $event),
          class: [[{ 'p-invalid': _ctx.errors['name'] }], "w-1 py-0"],
          style: {"min-width":"35px"},
          max: 62,
          min: 2
        }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "class"]),
        (_ctx.errors[`payment_term${index}`])
          ? (_openBlock(), _createBlock("small", _hoisted_2, _toDisplayString(_ctx.errors[`payment_term${index}`]), 1))
          : _createCommentVNode("", true),
        _createVNode("label", _hoisted_3, _toDisplayString(_ctx.$tc('general.day', 0)), 1)
      ]))
    }), 128))
  ]))
}
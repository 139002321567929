import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "md:col-6" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganizationDetail = _resolveComponent("OrganizationDetail")!
  const _component_PButton = _resolveComponent("PButton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.organization && _ctx.organization.id && _ctx.organization.id !== 0)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
            }, [
              _createVNode(_component_OrganizationDetail, {
                organization: _ctx.organization,
                class: "p-mt-4"
              }, null, 8, ["organization"]),
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_PButton, {
                  label: "Terug",
                  class: "p-button-secondary mr-3",
                  onClick: _ctx.back
                }, null, 8, ["onClick"]),
                _createVNode(_component_PButton, {
                  label: "Aanpassen",
                  class: "p-button-secondary mr-3",
                  onClick: _ctx.toEdit
                }, null, 8, ["onClick"])
              ])
            ], 32)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
<template>
	<div class="login-body">
		<div class="login-wrapper">
			<div class="login-panel">
				<div class="logo-container">
					<img src="/layout/images/logo-dark.svg" class="logo" alt="diamond-layout" />
					<img src="/layout/images/logo-text-dark.svg" class="text" alt="diamond-layout" />
				</div>

				<template v-if="loginType === 'ms'">
					<div class="login-form">
						<h2>Microsoft login</h2>
						<Button label="Login" @click="microsoftLogin" />
					</div>
					<div/>
					<!-- <p>{{ $t('pages.login.changeLoginType', { type: 'e-mail' }) }} <a href="/" @click.prevent="toggleLoginType">Klik hier</a></p> -->
				</template>

				<template v-if="loginType === 'email'">
					<div class="login-form">
						<h2>{{ $t('pages.login.emailLogin') }}</h2>
						<InputText id="email" placeholder="E-mail" v-model="inputEmail"/>
						<Password placeholder="Wachtwoord" style="width: 85%" v-model="inputPassword" @keyup.enter="emailLogin" :feedback="false"/>
						<Button label="Login" type="button" @click="emailLogin"></Button>
					<small v-if="showError" class="p-error">
						{{ $t('pages.login.invalidCredentials') }}
					</small>
					</div>
					<div/>
					<!-- <p>{{ $t('pages.login.changeLoginType', { type: 'Microsoft' }) }} <a href="/" @click.prevent="toggleLoginType">{{ $t('actions.clickHere') }}</a></p> -->
				</template>
			</div>
			<div class="login-image">
				<div class="login-image-content">
					<h1>Easy peasy,</h1>
					<h1>Lemon Squeezy</h1>
					<h3>
						Met Squeezy wordt factuuradministratie een eitje.<br />
						Maak, bevestig, verstuur en beheer facturen en <br />
						offertes op eenvoudige wijze.
					</h3>
				</div>
				<div class="image-footer">
					<p>Ontwikkeld door <a class="text-white" href="https://www.krm.nl/">{{ developerName }}</a></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {ref} from 'vue';
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {PButton, Card} from "@/plugins/PrimeVue";
import {UserActions} from "@/store/modules/user";
import { getAccessToken } from '@/utils/accessToken';

export default {
	components: {
		PButton,
		Card
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		let loginType = ref('email');

		const inputEmail = ref();
		const inputPassword = ref();
		const showError = ref(false);

		// const toggleLoginType = () => {
		// 	loginType.value = loginType.value === 'ms' ? 'email' : 'ms'
		// };

		const microsoftLogin = async () => {
			const authenticated = await store.dispatch(UserActions.getToken);
			if (authenticated) {
				await router.push({name: 'organizations'});
			}
		};

		const emailLogin = async () => {
			const authenticated = await store.dispatch(UserActions.localLogin, {username: inputEmail.value, password: inputPassword.value});
			if (authenticated) {
				showError.value = false;
				return await router.push({name: 'organizations'});
			}
			showError.value = true;
		};

		const developerName = process.env.VUE_APP_DEVELOPER_NAME
		return {
			loginType,
			inputEmail,
			inputPassword,
			developerName,
			// toggleLoginType,
			microsoftLogin,
			emailLogin,
			showError,
		};
	},
};
</script>


import { defineComponent, ref } from 'vue';
import { Tag, Dialog, Tooltip } from '@/plugins/PrimeVue';

export default defineComponent({
	components: {
		Tag,
		Dialog,
	},
	directives: {
		tooltip: Tooltip,
	},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		tagTitle: {
			type: String,
			required: true,
		},
		tagTooltip: {
			type: String,
			required: true,
		},
		dialogTitle: {
			type: String,
			required: true,
		},
		confirmEvent: {
			type: Function,
			required: true,
		},
		buttonColor: {
			type: String,
			required: false
		},
	},
	setup(props) {
		const isDisplayOpen = ref(false);
		const openConfirmation = () => isDisplayOpen.value = true;
		const closeConfirmation = () => isDisplayOpen.value = false;

		const confirmAndClose = () => {
			props.confirmEvent();
			isDisplayOpen.value = false;
		};

		return { isDisplayOpen, openConfirmation, closeConfirmation, confirmAndClose };
	},
});

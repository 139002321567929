import { ApiInstance } from '@/plugins/Axios';
import { InvoiceTemplateDetail, InvoiceTemplate } from '@/interfaces';

// TODO write url generation util
function setInvoiceTemplateApiURL (organizationId: number | undefined, templateId: number | null = null) {
	const baseUrl = `/api/organizations/${organizationId}/invoice-templates/`;

	return templateId ? `${baseUrl}${templateId}` : baseUrl;
}

export const InvoiceTemplateService = (api: ApiInstance) => ({
	async getAll(organizationId: number) {
		return api.get<InvoiceTemplate[]>(setInvoiceTemplateApiURL(organizationId));
	},
	async get(organizationId: number, templateId: number) {
		return api.get<InvoiceTemplateDetail>(setInvoiceTemplateApiURL(organizationId, templateId));
	},
	async create(organizationId: number, data: InvoiceTemplate) {
		// @ts-ignore
		data.clientId = data.client;

		return api.post(setInvoiceTemplateApiURL(organizationId), data);
	},
	async update(organizationId: number, data: InvoiceTemplate) {
		return api.patch<InvoiceTemplate>(setInvoiceTemplateApiURL(organizationId, data.id), data);
	},
	async delete(organizationId: number, templateId: number) {
		return api.delete<InvoiceTemplateDetail>(setInvoiceTemplateApiURL(organizationId, templateId));
	},
});

import { ApiInstance } from '@/plugins/Axios';
import { Organization, OrganizationUpdate } from '@/interfaces';

const OrganizationApiUrl = '/api/organizations/';

export const OrganizationService = (api: ApiInstance) => ({
	async getAll() {
		return api.get<Organization[]>(`${OrganizationApiUrl}`);
	},
	async get(id: number) {
		return api.get<Organization>(`${OrganizationApiUrl}${id}`);
	},
	async create(data: Organization) {
		const formData = Object.keys(data).reduce(
			(formData, key) => {
				// @ts-ignore
				let tempData = data[key];

				// @ts-ignore
				if (typeof data[key] === "object" && key !== "logo") {
					// @ts-ignore
					tempData = JSON.stringify(data[key]);
				}

				// @ts-ignore
				formData.append(key, tempData);
				return formData;
			},
			new FormData()
		);

		return api.post(`${OrganizationApiUrl}`, formData);
	},
	async update(id: number, data: OrganizationUpdate) {
		const formData = Object.keys(data).reduce(
			(formData, key) => {
				if (key === 'users') {
					return formData;
				}

				// @ts-ignore
				let tempData = data[key];

				// @ts-ignore
				if (typeof data[key] === "object" && key !== "logo") {
					// @ts-ignore
					tempData = JSON.stringify(data[key]);
				}

				// @ts-ignore
				formData.append(key, tempData);
				return formData;
			},
			new FormData()
		);

		return api.put<Organization>(`${OrganizationApiUrl}${id}`, formData);
	},
	async delete(id: number) {
		return api.delete<Organization>(`${OrganizationApiUrl}${id}`);
	},
});

import axios from 'axios';

export const createError = (error: unknown) => {
	if (!axios.isAxiosError(error) || !error.response) {
		return {};
	}
	if (typeof error.response.data.message === 'string') {
		return { detail: error.response.data.message };
	}
	
	if (Array.isArray(error.response.data.message)) {
		return { detail: error.response.data.message[0] };
	}

	if (typeof error.response.data.detail === 'string') {
		return { detail: error.response.data.detail };
	}

	return error?.response?.data?.validationErrors || {};
};

import router from '../router/index';
import { ApiInstance } from '@/plugins/Axios';
import { getAccessToken } from '@/utils/accessToken';

export const initializeInterceptors = (api: ApiInstance) => {
	api.interceptors.request.use(
		async (config) => {
			const newConfig = config;
			const accessToken = getAccessToken();

			newConfig.headers = {
				...newConfig.headers,
				Authorization: `Bearer ${accessToken}`,
			};

			return newConfig;
		},
		(error) => {
			Promise.reject(error);
		},
	);

	api.interceptors.response.use(
		response => response,
		async (error) => {
			const originalRequest = error.config;

			// eslint-disable-next-line
			if ((error.response && error.response.status === 403) && !originalRequest._retry) {
				// eslint-disable-next-line
				originalRequest._retry = true;

				const accessToken = getAccessToken();

				if (!accessToken) {
					return router.push({ name: 'login' });
				}

				// eslint-disable-next-line
				api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

				return api(originalRequest);
			}

			return Promise.reject(error);
		},
	);
};

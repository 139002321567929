
import { defineComponent, ref, watch } from 'vue';
import { isEqual } from 'lodash';
import { InvoiceTemplate } from '@/interfaces';
import { InputText, InputNumber } from '@/plugins/PrimeVue';
import ClientForm from '@/components/ClientForm/ClientForm.vue';
import InvoiceTemplateInput from '@/components/InvoiceTemplateForm/InvoiceTemplateInput.vue';
import Draggable from "vuedraggable";
import moment from "moment";
import useInvoices from "@/composable/useInvoices";

export default defineComponent({
	components: {
		ClientForm,
		Draggable,
		InputText,
		InputNumber,
		InvoiceTemplateInput,
	},
	props: {
		invoiceTemplate: {
			type: Object as () => InvoiceTemplate,
			required: true,
			default: {},
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const { invoices } = useInvoices();
		const calcInvoiceAmount = invoices;
		const dateToday = moment().format('YYYY-MM-DD');
		const invoiceNumber = dateToday+('-xxxx');
		const internalInvoiceTemplate = ref({...props.invoiceTemplate});
		const invoiceTemplatePropsList = ref();

		const syncList = () => {
			internalInvoiceTemplate.value.inputs = invoiceTemplatePropsList.value;

			emit('update:errors', internalInvoiceTemplate.value.inputs);

			if ( !isEqual(props.invoiceTemplate, internalInvoiceTemplate.value.inputs) ) {
				emit('update:invoiceTemplate', internalInvoiceTemplate.value);
			}
		};

		if (!internalInvoiceTemplate.value.id) {
			invoiceTemplatePropsList.value = [
				{id: 'invoiceNumber', name: 'Factuurnummer', type: 'string', value: invoiceNumber},
				{id: 'invoiceDate', name: 'Factuurdatum', type: 'string'},
				{id: 'debtorNumber', name: 'Debiteurnummer', type: 'string'},
				{id: 'purchaseNumber', name: 'Inkoopnummer', type: 'string'},
				{id: 'concern', name: 'Betreft klant/resource', type: 'string'},
				{id: 'specification', name: 'Specificatie', type: 'textarea'},
				{id: 'products', name: 'Producten', type: 'array', value: []},
			];

			syncList();
		} else {
			invoiceTemplatePropsList.value = internalInvoiceTemplate.value.inputs;
		}

		watch(() => props.invoiceTemplate, () => {
			internalInvoiceTemplate.value = props.invoiceTemplate;

			if (props.invoiceTemplate.id > 0) {
				invoiceTemplatePropsList.value = internalInvoiceTemplate.value.inputs;
			}
		});

		watch(invoiceTemplatePropsList.value, () => {
			syncList();
		});

		const removeAt = (index:any) => {
			invoiceTemplatePropsList.value?.splice(invoiceTemplatePropsList.value.indexOf(index), 1);
		};

		return {
			internalInvoiceTemplate,
			invoiceTemplatePropsList,
			invoiceNumber,
			dateToday,
			calcInvoiceAmount,
			removeAt,
		};
	},
});


import { defineComponent } from 'vue';
import { InvoicePropertyItem } from '@/interfaces';
import { formatPrice } from '@/utils/formatPrice';

export default defineComponent({
	props: {
		item: {
			type: Object as () => InvoicePropertyItem,
			default: () => ({}),
		},
		alignRight: {
			type: Boolean,
			default: false,
		},
		hasButton: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return { formatPrice };
	},
});

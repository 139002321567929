import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { Organization } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	organizations(state: S): Organization[];
	organization(state: S): Organization;
	selectedOrganization(state: S): Organization
	organizationErrors(state: S): Record<string, string>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	organizations: state => state.organizations,
	organization: state => {
		return state.organization || ({} as Organization);
	},
	selectedOrganization: state => state.selectedOrganization,
	organizationErrors: state => state.errors,
};

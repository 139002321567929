
import { defineComponent, Ref, ref, watch } from 'vue';
import { InputText, InputNumber, Button, Divider } from '@/plugins/PrimeVue';
import { InvoicePropertyItem, PropertyList } from '@/interfaces';
import { cloneDeep } from "lodash";
import useInvoicePropertyList from '@/composable/useInvoicePropertyList';
import PriceItem from './PriceItem/PriceItem.vue';

export interface InvoicePaymentItem {
	description: string,
	price: number,
	vat: string,
}

export default defineComponent({
	components: {
		InputText,
		InputNumber,
		Button,
		Divider,
		PriceItem,
	},
	props: {
		property: {
			type: Object as () => PropertyList,
			default: () => ({}),
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:property'],
	setup(props, { emit }) {
		const calculatedItems = ref(useInvoicePropertyList({ ...props.property }).calculatedItems.value);
		const vatPercentages = ref([
			{name: '21%', percentage: '21'},
			{name: '9%', percentage: '9'},
			{name: '0%', percentage: '0'}
		]);

		const propertyRef = ref<PropertyList>({ ...props.property, items: [] });
		const propertyItemsRef = ref<InvoicePropertyItem[]>([{ description: '', price: 0, vat: '21' }]);

		if (props.property.items?.length) {
			propertyRef.value = cloneDeep(props.property);
			propertyItemsRef.value = cloneDeep([...props.property.items, { description: '', price: 0, vat: '21' }]);
		}

		const propRefItemUpdater = () => {
			const index = propertyItemsRef.value.length - 1

			if ( propertyItemsRef.value[index].description.length > 0 ) {
				propertyRef.value.items = [...propertyItemsRef.value];

				propertyItemsRef.value.push({ description: '', price: 0, vat: '21' });

				emit('update:property', propertyRef.value);
			}

			calculatedItems.value = useInvoicePropertyList(propertyRef.value).calculatedItems.value;
		};

		watch(propertyItemsRef.value, propRefItemUpdater);

		const removeItem = (item: number) => {
			propertyItemsRef.value.splice(item, 1);

			calculatedItems.value = useInvoicePropertyList(propertyRef.value).calculatedItems.value;
		}

		return {
			propertyRef,
			vatPercentages,
			calculatedItems,
			propertyItemsRef,
			removeItem,
		};
	},
});

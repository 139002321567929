import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { Notification } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	notifications(state: S): Notification[];
	notification(state: S): (id: number) => Notification;
	notificationErrors(state: S): Record<string, string>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	notifications: state => state.notifications,
	notification: state => (id: number): Notification => {
		const single = state.notifications.find(notification => notification.id === id);
		return single || ({} as Notification);
	},
	notificationErrors: state => state.errors,
};

import { onMounted, computed } from 'vue';
import { useStore } from '@/store';
import { InvoiceTemplate } from '@/interfaces';
import { InvoiceTemplateActions } from '@/store/modules/invoiceTemplate';
import { INVOICE_TEMPLATE } from '@/constants/messages';
import { useToast } from 'primevue/usetoast';
import { ToastMessageOptions } from "primevue/toast";

export default function useInvoiceTemplates() {
	const store = useStore();
	const toast = useToast();

	const getInvoiceTemplates = () => {
		if (
			!store.getters.selectedOrganization ||
			store.getters.selectedOrganization.id === undefined
		) {
			return;
		}

		const organizationId = store.getters.selectedOrganization.id;

		store.dispatch(InvoiceTemplateActions.getAll, organizationId);
	};

	onMounted(getInvoiceTemplates);

	const invoiceTemplates = computed(() => store.getters.invoiceTemplates);
	const selectedInvoiceTemplate = computed(() => store.getters.selectedInvoiceTemplate);
	const templateOptions = computed(
		() => invoiceTemplates.value && invoiceTemplates.value.map((template: InvoiceTemplate) => ({
			name: template.name,
			value: template.id,
		})),
	);

	const setSelectedInvoiceTemplate = (invoiceTemplate: InvoiceTemplate) => {
		store.dispatch(InvoiceTemplateActions.setSelected, invoiceTemplate);
	}

	const errors = computed(() => store.getters.errors);

	if (errors.value && errors.value.detail !== undefined) {
		toast.add(INVOICE_TEMPLATE.ERROR(errors.value.detail) as ToastMessageOptions);
	}

	return {
		getInvoiceTemplates,
		setSelectedInvoiceTemplate,
		selectedInvoiceTemplate,
		invoiceTemplates,
		templateOptions,
	};
}


import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, PButton } from '@/plugins/PrimeVue';
import OrganizationDetail from '@/components/OrganizationDetail/OrganizationDetail.vue';
import useOrganization from '@/composable/useOrganization';

export default defineComponent({
	components: {
		Panel,
		OrganizationDetail,
		PButton,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const title = ref(route.meta.title);
		const organizationId = +route.params.id || 0;

		const {
			organization,
			errors,
		} = useOrganization(true, organizationId);

		const back = () => {
			router.push({ name: 'organizations' });
		};

		const toEdit = () => {
			router.push({ name: 'organization-edit', params: { organizationId } });
		};

		return {
			title,
			organization,
			organizationId,
			back,
			toEdit,
			errors,
		};
	},
});


import { Client } from '@/interfaces';
import { InputText, ListBox } from '@/plugins/PrimeVue';
import CheckBox from "primevue/checkbox";
import { isEqual } from 'lodash';
import { defineComponent, ref, watch, Ref } from 'vue';
import ContactPersonList from '@/components/ClientForm/ContactPersonList/ContactPersonList.vue';

interface FileEvent {
	originalEvent: Event;
	files: File[];
}

export default defineComponent({
	components: {
		InputText,
		ListBox,
		CheckBox,
		ContactPersonList,
	},
	props: {
		client: {
			type: Object as () => Client,
			default: {},
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
	},
	setup(props) {
		const identicalAddress: Ref<Boolean> = ref(false);
		const InternalClient: Ref<Client> = ref({ ...props.client });
		const OrganizationSelection = ref([]);
		const input = ref();

		if (isEqual(InternalClient.value.postal_address, InternalClient.value.billing_address)) {
			identicalAddress.value = true;
		}

		watch(InternalClient, () => {
			InternalClient.value = props.client;
		});

		return {
			identicalAddress,
			InternalClient,
			OrganizationSelection,
			input
		};
	},
});

import { MutationTree } from 'vuex';
import { User } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_USER = 'SET_USER',
	RESET_USER = 'RESET_USER',
	SET_ERRORS = 'SET_ERRORS',
	SET_ALL = 'SET_ALL_USERS',
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: User[]): void;
	[MutationTypes.SET_USER](state: S, payload: User): void;
	[MutationTypes.RESET_USER](state: S): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_USER](state, payload) {
		state.user = payload;
	},
	[MutationTypes.SET_ALL](state, payload) {
		state.users = payload;
	},
	[MutationTypes.RESET_USER](state) {
		state.user = { id: '', name: '', username: '' };
		localStorage.clear();
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
};

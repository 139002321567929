import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "md:col-6" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "flex justify-content-end" }
const _hoisted_5 = {
  key: 1,
  class: "card"
}
const _hoisted_6 = { class: "flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClientForm = _resolveComponent("ClientForm")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.client && _ctx.client.id)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitUpdate && _ctx.submitUpdate(...args)), ["prevent"]))
            }, [
              _createVNode(_component_ClientForm, {
                client: _ctx.client,
                organizations: _ctx.organizations,
                errors: _ctx.errors,
                class: "p-mt-4",
                "onUpdate:client": _ctx.updateClient,
                "onUpdate:errors": _ctx.updateErrors
              }, null, 8, ["client", "organizations", "errors", "onUpdate:client", "onUpdate:errors"]),
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_Button, {
                  class: "p-button-secondary mr-3",
                  "data-testid": "secondary-btn",
                  icon: "pi pi-times",
                  label: "Annuleren",
                  onClick: _ctx.back
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  label: _ctx.saveButtonLabel,
                  "data-testid": "primary-btn",
                  icon: "pi pi-check",
                  type: "submit"
                }, null, 8, ["label"])
              ])
            ], 32)
          ]))
        : (_ctx.clientId === 0)
          ? (_openBlock(), _createBlock("div", _hoisted_5, [
              _createVNode("form", {
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
              }, [
                _createVNode(_component_ClientForm, {
                  organizations: _ctx.organizations,
                  errors: _ctx.errors,
                  class: "p-mt-4",
                  "onUpdate:client": _ctx.updateClient,
                  "onUpdate:errors": _ctx.updateErrors
                }, null, 8, ["organizations", "errors", "onUpdate:client", "onUpdate:errors"]),
                _createVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary mr-3",
                    "data-testid": "secondary-btn",
                    icon: "pi pi-times",
                    label: "Annuleren",
                    onClick: _ctx.back
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    label: _ctx.saveButtonLabel,
                    "data-testid": "primary-btn",
                    icon: "pi pi-check",
                    type: "submit"
                  }, null, 8, ["label"])
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}
import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { User } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	user(state: S): User;
	users(state: S): User[];
	errors(state: S): Record<string, string>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	user: state => state.user,
	users: state => state.users,
	errors: state => state.errors,
};

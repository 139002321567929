
import { defineComponent, ref, watch, Ref } from 'vue';
import { useRoute } from "vue-router";
import useInvoice from '@/composable/useInvoice/index';
import { Button } from '@/plugins/PrimeVue';
import useOrganization from '@/composable/useOrganization';
import DTH from "@/utils/dynamicTemplateHelper";

export default defineComponent({
	props: {
		hasDownload: {
			type: Boolean,
			default: true
		}
	},
	components: {
		Button,
	},
	setup(props) {
		const route = useRoute();
		const invoiceId = +route.params.id || 0;
		const organizationId = +route.params.organizationId || 0;
		const reminderIndex = +route.params.reminderIndex || 0;
		const isCreate = !invoiceId;
		const invoiceTemplateId = ref(isCreate ? parseInt(`${ route.query.template || 1 }`, 10) : 0);
		const { invoice, } = useInvoice(true, invoiceId, organizationId)
		const { organization } = useOrganization(true, organizationId);
		const hasDownload = ref(props.hasDownload);
		const imageUrl = ref('');
		const reminderMessage = ref('');

		imageUrl.value = `${ process.env.VUE_APP_API_URL }/${ organization.value.logo }`;

		watch(organization, () => {
			imageUrl.value = `${ process.env.VUE_APP_API_URL }/${ organization.value.logo }`;
		});

		const formatter = new Intl.NumberFormat('nl-NL', {
			style: 'currency',
			currency: 'EUR',
		});

		interface PriceData {
			rawTotal: number,
			netTotal: number,
			vatTotal: number,
			perVatPercentage: Record<string, PartialPriceData>,
		}

		interface PartialPriceData {
			rawTotal: number,
			netTotal: number,
			percentage: number,
		}

		const priceData: Ref<PriceData> = ref({
			rawTotal: 0,
			netTotal: 0,
			vatTotal: 0,
			perVatPercentage: {}
		});
		const totalWithoutVat = ref(0);
		const vatPrice = ref(0);
		const totalWithVat = ref(0);

		const updatePrices = () => {
			if ( !invoice.value.propertyList ) return;

			priceData.value = invoice.value.propertyList.reduce(
				(acc, obj) => {
					const onePercent = obj.price * 0.01;

					acc.rawTotal += obj.price;
					acc.netTotal += onePercent * (100 + (+obj.vat));
					acc.vatTotal += onePercent * (+obj.vat);

					if ( !acc.perVatPercentage[obj.vat] ) {
						acc.perVatPercentage[obj.vat] = {
							rawTotal: 0,
							netTotal: 0,
							percentage: +obj.vat,
						}
					}

					const vatObj = acc.perVatPercentage[obj.vat];

					vatObj.rawTotal += obj.price;
					vatObj.netTotal += obj.price / 100 * vatObj.percentage;

					return acc;
				},
				{ rawTotal: 0, netTotal: 0, vatTotal: 0, perVatPercentage: {} } as PriceData
			);

			vatPrice.value = totalWithoutVat.value * 0.21;
			totalWithVat.value = totalWithoutVat.value + vatPrice.value;
		}

		const setReminderMessage = () => {
			const trueReminderIndex = reminderIndex - 1

			const messageIndex = (invoice.value.reminder_messages.length <= trueReminderIndex) ?
				invoice.value.reminder_messages.length - 1 :
				trueReminderIndex;

			const paymentReminderIndex = (invoice.value.paymentReminders.length <= (trueReminderIndex)) ?
				invoice.value.reminder_messages.length - 1 :
				trueReminderIndex;

			reminderMessage.value = DTH.replaceTemplate(
				invoice.value.reminder_messages[messageIndex],
				{
					nextInterval: `${invoice.value.paymentReminders[paymentReminderIndex]}`
				}
			);
		}

		watch(invoice, () => {

			if ( invoice.value.invoiceTemplateId ) {
				invoiceTemplateId.value = invoice.value.invoiceTemplateId;
			}

			updatePrices();
			setReminderMessage();
		});

		return {
			reminderMessage,
			invoice,
			priceData,
			organization,
			formatter,
			hasDownload,
			imageUrl,
		};
	},
});

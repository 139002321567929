import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { InvoiceTemplate } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	invoiceTemplates(state: S): InvoiceTemplate[];
	invoiceTemplate(state: S): InvoiceTemplate;
	selectedInvoiceTemplate(state: S): InvoiceTemplate;
	invoiceTemplateErrors(state: S): Record<string, string>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	invoiceTemplates: state => state.invoiceTemplates,
	invoiceTemplate: state => state.invoiceTemplate,
	selectedInvoiceTemplate: state => state.invoiceTemplate,
	invoiceTemplateErrors: state => state.errors,
};

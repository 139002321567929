
import useInvoiceTemplate from "@/composable/useInvoiceTemplate";
import useOrganizations from "@/composable/useOrganizations";
import { Organization } from "@/interfaces";
import { defineComponent, watch, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import DTH from "@/utils/dynamicTemplateHelper";

export default defineComponent({
		setup() {
			const route = useRoute();
			const router = useRouter()
			const invoiceTemplateId = +route.params.id || 0;
			const { selectedOrganization } = useOrganizations();
			const imageUrl = ref('');
			const reminderMessage = ref('');

			const usedOrganization = ref<Organization>(
				selectedOrganization.value.postal_address && selectedOrganization.value.id !== 0 ?
					selectedOrganization.value :
					{postal_address: {}} as Organization
			);

			const {
				storedInvoiceTemplate,
				getTemplateById,
			} = useInvoiceTemplate();

			imageUrl.value = `${process.env.VUE_APP_API_URL}/${selectedOrganization.value.logo}`;
			watch(selectedOrganization, () => {
				imageUrl.value = `${ process.env.VUE_APP_API_URL }/${ selectedOrganization.value.logo }`;
				getTemplateById(invoiceTemplateId, true);
			});

			watch(selectedOrganization, () => {
				router.push({ name: 'invoice-template' });
			});

			watch(storedInvoiceTemplate, () => {
				reminderMessage.value = DTH.replaceTemplate(
					storedInvoiceTemplate.value.reminder_messages[0],
					{
						nextInterval: 'x'
					}
				);
			});

			return {
				reminderMessage,
				storedInvoiceTemplate,
				usedOrganization,
				imageUrl
			}
		}
	})

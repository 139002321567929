import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode(_component_Button, {
      label: _ctx.tagTitle,
      class: `p-button-rounded p-button-${_ctx.buttonColor ?? 'info'}`,
      role: "button",
      onClick: _ctx.openConfirmation
    }, null, 8, ["label", "class", "onClick"]), [
      [
        _directive_tooltip,
        _ctx.tagTooltip,
        void 0,
        { top: true }
      ]
    ]),
    (!_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          header: _ctx.dialogTitle,
          visible: _ctx.isDisplayOpen,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDisplayOpen = $event)),
          style: {width: '900px'},
          modal: true
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Annuleer",
              icon: "pi pi-times",
              onClick: _ctx.closeConfirmation,
              class: "p-button-text"
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              label: "Bevestig",
              icon: "pi pi-check",
              onClick: _ctx.confirmAndClose,
              class: "p-button-text",
              autofocus: "",
              "data-testid": "confirm"
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["header", "visible"]))
      : _createCommentVNode("", true)
  ]))
}
<template>
	<div class="exception-body access">
		<img src="layout/images/logo-white.svg" alt="diamond-layout" class="logo" />

		<div class="exception-content">
			<div class="exception-title">ACCESS DENIED</div>
			<div class="exception-detail">You do not have the necessary permissions.</div>
			<router-link to="/">Go to Dashboard</router-link>
		</div>
	</div>
</template>

<script>
export default {};
</script>

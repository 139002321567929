
import { defineComponent, ref, watch } from 'vue';
import Notification from '@/components/NotificationsList/Notification/Notification.vue';
import { Button, OverlayPanel } from '@/plugins/PrimeVue';
import useNotifications from '@/composable/useNotifications';

export default defineComponent({
	components: {
		Notification,
		Button,
		OverlayPanel,
	},
	setup() {
		const { notifications, getNotifications, deleteNotification } = useNotifications();
		const op = ref();
		const notificationsRef = ref();
		const toggle = (event: Event) => {
			op.value.toggle(event);
		};
		
		const clearNotification = async (item: number) => {
			await deleteNotification(item);
			getNotifications();
		};
		
		watch(notifications.value, () => {
			notificationsRef.value = notifications.value;
		});
		
		return { notifications, toggle, op, clearNotification, getNotifications };
	},
});

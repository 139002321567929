import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { HttpInvoice } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	invoices(state: S): HttpInvoice[];
	invoice(state: S): HttpInvoice;
	invoiceErrors(state: S): Record<string, string>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	invoices: state => state.invoices,
	invoice: state => state.invoice,
	invoiceErrors: state => state.errors,
};

import { ActionContext, ActionTree } from 'vuex';
import { isEqual } from 'lodash';
import { api } from '@/api';
import { RootState } from '@/store/state';
import { apiKeyVariables, HttpInvoice } from '@/interfaces';
import { validateInvoiceProperties } from '@/utils/validateInvoice';
import { createError } from '@/utils/createError';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';

export enum ActionTypes {
	getAll = 'INVOICE__GET_ALL',
	getSingle = 'INVOICE__GET_SINGLE',
	create = 'INVOICE__CREATE',
	update = 'INVOICE__UPDATE',
	updateStatus = 'INVOICE__UPDATE_STATUS',
	resetErrors = 'INVOICE__RESET_ERRORS',
	updateErrors = 'INVOICE__UPDATE_ERRORS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export type IdPayload = {
	id: number;
	organizationId: number;
}

export interface Actions {
	[ActionTypes.getAll]({ commit }: AugmentedActionContext, payload: number ): void;
	[ActionTypes.getSingle]({ commit }: AugmentedActionContext, payload: IdPayload ): void;
	[ActionTypes.create]({ commit }: AugmentedActionContext, payload: HttpInvoice): void;
	[ActionTypes.update]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
	[ActionTypes.updateStatus]({ commit }: AugmentedActionContext, payload: any): Promise<boolean>;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
	[ActionTypes.updateErrors]({ commit }: AugmentedActionContext, payload: {}): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getAll]({ commit }, payload) {
		if (!(payload > 0)) {
			return;
		}

		try {
			const response = await api.invoice.getAll(payload);
			if (response) {
				commit(MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.getSingle]({ commit, getters }, payload) {
		try {
			const usedOrganizationId = payload.organizationId ?
				payload.organizationId :
				getters.selectedOrganization.id;

			const response = await api.invoice.get(usedOrganizationId, payload.id);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.create]({ commit }, payload) {
		try {
			const response = await api.invoice.create(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
				return true;
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
		return false;
	},
	async [ActionTypes.update]({ commit, getters }, payload): Promise<boolean> {
		let errors = {};

		if (payload.properties) {
			errors = validateInvoiceProperties(payload.properties);
		}

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			return false;
		}

		commit(MutationTypes.SET_ERRORS, {});

		try {
			payload.organizationId = payload.organization;

			const response = await api.invoice.update(payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.updateStatus]({ commit, getters }, payload): Promise<boolean> {
		try {
			const response = await api.invoice.updateStatus(payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
	async [ActionTypes.updateErrors]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, payload);
	},
};

import { ApiInstance } from '@/plugins/Axios';
import { HttpNotification, Notification, NotificationUpdate } from '@/interfaces';

const NotificationApiUrl = '/api/notifications/';

export const NotificationService = (api: ApiInstance) => ({
	async getAll() {
		return api.get<Notification[]>(`${NotificationApiUrl}`);
	},
	async get(id: number) {
		return api.get<Notification>(`${NotificationApiUrl}${id}`);
	},
	async create(data: HttpNotification) {
		return api.post(`${NotificationApiUrl}`, data);
	},
	async update(id: number, data: NotificationUpdate) {
		return api.put<Notification>(`${NotificationApiUrl}${id}`, data);
	},
	async delete(id: number) {
		return api.delete<Notification>(`${NotificationApiUrl}${id}`);
	},
});

import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid formgrid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "field mb-4 md:col-12 pt-0 pb-0" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_12 = {
  key: 0,
  class: "p-error"
}
const _hoisted_13 = { class: "field mb-4 md:col-12 pt-0 pb-0" }
const _hoisted_14 = {
  key: 0,
  class: "p-error"
}
const _hoisted_15 = { class: "field mb-4 col-12" }
const _hoisted_16 = {
  key: 0,
  class: "p-error"
}
const _hoisted_17 = { class: "field mb-4 col-12" }
const _hoisted_18 = {
  key: 0,
  class: "p-error"
}
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "field mb-4 md:col-12 pt-0 pb-0" }
const _hoisted_21 = { class: "col-12" }
const _hoisted_22 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_23 = {
  key: 0,
  class: "p-error"
}
const _hoisted_24 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_25 = {
  key: 0,
  class: "p-error"
}
const _hoisted_26 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_27 = {
  key: 0,
  class: "p-error"
}
const _hoisted_28 = { class: "field mb-4 md:col-9 pt-0 pb-0" }
const _hoisted_29 = {
  key: 0,
  class: "p-error"
}
const _hoisted_30 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_31 = {
  key: 0,
  class: "p-error"
}
const _hoisted_32 = { class: "field mb-4 col-12" }
const _hoisted_33 = {
  key: 0,
  class: "p-error"
}
const _hoisted_34 = { class: "col-12" }
const _hoisted_35 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_36 = {
  key: 0,
  class: "p-error"
}
const _hoisted_37 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_38 = {
  key: 0,
  class: "p-error"
}
const _hoisted_39 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_40 = {
  key: 0,
  class: "p-error"
}
const _hoisted_41 = { class: "field mb-4 md:col-9 pt-0 pb-0" }
const _hoisted_42 = {
  key: 0,
  class: "p-error"
}
const _hoisted_43 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_44 = {
  key: 0,
  class: "p-error"
}
const _hoisted_45 = { class: "field mb-4 col-12" }
const _hoisted_46 = {
  key: 0,
  class: "p-error"
}
const _hoisted_47 = { class: "col-12" }
const _hoisted_48 = { class: "field mb-4 md:col-12 pt-0 pb-0" }
const _hoisted_49 = {
  style: {"margin-left":"8px"},
  for: "use-mailbox"
}
const _hoisted_50 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_51 = {
  key: 0,
  class: "p-error"
}
const _hoisted_52 = { class: "field mb-4 md:col-3 pt-0 pb-0" }
const _hoisted_53 = {
  key: 0,
  class: "p-error"
}
const _hoisted_54 = { class: "field mb-4 md:col-6 pt-0 pb-0" }
const _hoisted_55 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ContactPersonList = _resolveComponent("ContactPersonList")!
  const _component_CheckBox = _resolveComponent("CheckBox")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t('fields.clients.clientInfo')), 1),
    _createVNode("div", _hoisted_3, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.clientName')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.InternalClient.name = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['name'] }],
        placeholder: _ctx.$t('fields.clients.clientName'),
        "data-testid": "client-name"
      }, null, 8, ["id", "modelValue", "class", "placeholder"]),
      (_ctx.errors['name'])
        ? (_openBlock(), _createBlock("small", _hoisted_4, _toDisplayString(_ctx.errors['name']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.kvk')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.kvk_number,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.InternalClient.kvk_number = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['kvk_number'] }],
        "data-testid": "kvk-number",
        placeholder: "12345678"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['kvk_number'])
        ? (_openBlock(), _createBlock("small", _hoisted_6, _toDisplayString(_ctx.errors['kvk_number']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.btw')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.btw_number,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.InternalClient.btw_number = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['btw_number'] }],
        "data-testid": "btw-number",
        placeholder: "NL123456789B01"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['btw_number'])
        ? (_openBlock(), _createBlock("small", _hoisted_8, _toDisplayString(_ctx.errors['btw_number']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.iban')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.iban,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.InternalClient.iban = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['iban'] }],
        "data-testid": "iban",
        placeholder: "NL12ABCD1234567890"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['iban'])
        ? (_openBlock(), _createBlock("small", _hoisted_10, _toDisplayString(_ctx.errors['iban']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_11, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.bic')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.bic,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.InternalClient.bic = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['bic'] }],
        "data-testid": "bic",
        placeholder: "ABCDNL2A"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['bic'])
        ? (_openBlock(), _createBlock("small", _hoisted_12, _toDisplayString(_ctx.errors['bic']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_13, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.phone')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.phone_number,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.InternalClient.phone_number = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['phone_number'] }],
        "data-testid": "phone-number",
        placeholder: "+31612345678"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['phone_number'])
        ? (_openBlock(), _createBlock("small", _hoisted_14, _toDisplayString(_ctx.errors['phone_number']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_15, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.website')), 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.website_url,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.InternalClient.website_url = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['website_url'] }],
        "data-testid": "website-url",
        placeholder: "https://www.website.nl"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['website_url'])
        ? (_openBlock(), _createBlock("small", _hoisted_16, _toDisplayString(_ctx.errors['website_url']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_17, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.email')) + " *", 1),
      _createVNode(_component_InputText, {
        modelValue: _ctx.InternalClient.email,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.InternalClient.email = $event)),
        id: _ctx.InternalClient.id,
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['email'] }],
        placeholder: "email@email.nl",
        "data-testid": "email"
      }, null, 8, ["modelValue", "id", "class"]),
      (_ctx.errors['email'])
        ? (_openBlock(), _createBlock("small", _hoisted_18, _toDisplayString(_ctx.errors['email']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("h5", _hoisted_19, _toDisplayString(_ctx.$t('fields.clients.contactPoints')), 1),
    _createVNode("div", _hoisted_20, [
      _createVNode(_component_ContactPersonList, {
        client: _ctx.InternalClient,
        "onUpdate:client": _ctx.updateClient
      }, null, 8, ["client", "onUpdate:client"])
    ]),
    _createVNode("h5", _hoisted_21, _toDisplayString(_ctx.$t('fields.clients.postalAddress')), 1),
    _createVNode("div", _hoisted_22, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.street')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.postal_address.street,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.InternalClient.postal_address.street = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['postal_address-street'] }],
        "data-testid": "street",
        placeholder: "Straat"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['postal_address-street'])
        ? (_openBlock(), _createBlock("small", _hoisted_23, _toDisplayString(_ctx.errors['postal_address-street']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_24, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.house_number')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.postal_address.house_number,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.InternalClient.postal_address.house_number = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['postal_address-house_number'] }],
        "data-testid": "house-number",
        placeholder: "123"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['postal_address-house_number'])
        ? (_openBlock(), _createBlock("small", _hoisted_25, _toDisplayString(_ctx.errors['postal_address-house_number']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_26, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.suffix')), 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.postal_address.house_number_addition,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.InternalClient.postal_address.house_number_addition = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['postal_address-house_number_addition'] }],
        "data-testid": "house-number-addition"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['postal_address-house_number_addition'])
        ? (_openBlock(), _createBlock("small", _hoisted_27, _toDisplayString(_ctx.errors['postal_address-house_number_addition']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_28, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.place')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.postal_address.place,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.InternalClient.postal_address.place = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['postal_address-place'] }],
        "data-testid": "place",
        placeholder: "Plaats"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['postal_address-place'])
        ? (_openBlock(), _createBlock("small", _hoisted_29, _toDisplayString(_ctx.errors['postal_address-place']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_30, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.zipcode')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.postal_address.zipcode,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.InternalClient.postal_address.zipcode = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['postal_address-zipcode'] }],
        "data-testid": "zipcode",
        placeholder: "1234AB"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['postal_address-zipcode'])
        ? (_openBlock(), _createBlock("small", _hoisted_31, _toDisplayString(_ctx.errors['postal_address-zipcode']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_32, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.country')) + " *", 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.postal_address.country,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.InternalClient.postal_address.country = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['postal_address-country'] }],
        "data-testid": "country",
        placeholder: "Nederland"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['postal_address-country'])
        ? (_openBlock(), _createBlock("small", _hoisted_33, _toDisplayString(_ctx.errors['postal_address-country']), 1))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.identicalAddress)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode("h5", _hoisted_34, _toDisplayString(_ctx.$t('fields.clients.billingAddress')), 1),
          _createVNode("div", _hoisted_35, [
            _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.street')) + " *", 1),
            _createVNode(_component_InputText, {
              id: _ctx.InternalClient.id,
              modelValue: _ctx.InternalClient.billing_address.street,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.InternalClient.billing_address.street = $event)),
              class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['billing_address-street'] }],
              "data-testid": "billing-address-street"
            }, null, 8, ["id", "modelValue", "class"]),
            (_ctx.errors['billing_address-street'])
              ? (_openBlock(), _createBlock("small", _hoisted_36, _toDisplayString(_ctx.errors['billing_address-street']), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_37, [
            _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.house_number')) + " *", 1),
            _createVNode(_component_InputText, {
              id: _ctx.InternalClient.id,
              modelValue: _ctx.InternalClient.billing_address.house_number,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.InternalClient.billing_address.house_number = $event)),
              class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['billing_address-house_number'] }],
              "data-testid": "billing-address-house-number"
            }, null, 8, ["id", "modelValue", "class"]),
            (_ctx.errors['billing_address-house_number'])
              ? (_openBlock(), _createBlock("small", _hoisted_38, _toDisplayString(_ctx.errors['billing_address-house_number']), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_39, [
            _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.suffix')), 1),
            _createVNode(_component_InputText, {
              id: _ctx.InternalClient.id,
              modelValue: _ctx.InternalClient.billing_address.house_number_addition,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.InternalClient.billing_address.house_number_addition = $event)),
              class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['billing_address-house_number_addition'] }],
              "data-testid": "billing-address-house-number-addition"
            }, null, 8, ["id", "modelValue", "class"]),
            (_ctx.errors['billing_address-house_number_addition'])
              ? (_openBlock(), _createBlock("small", _hoisted_40, _toDisplayString(_ctx.errors['billing_address-house_number_addition']), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_41, [
            _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.place')) + " *", 1),
            _createVNode(_component_InputText, {
              modelValue: _ctx.InternalClient.billing_address.place,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.InternalClient.billing_address.place = $event)),
              id: _ctx.InternalClient.id,
              class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['billing_address-place'] }],
              "data-testid": "billing-address-place"
            }, null, 8, ["modelValue", "id", "class"]),
            (_ctx.errors['billing_address-place'])
              ? (_openBlock(), _createBlock("small", _hoisted_42, _toDisplayString(_ctx.errors['billing_address-place']), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_43, [
            _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.zipcode')) + " *", 1),
            _createVNode(_component_InputText, {
              id: _ctx.InternalClient.id,
              modelValue: _ctx.InternalClient.billing_address.zipcode,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.InternalClient.billing_address.zipcode = $event)),
              class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['billing_address-zipcode'] }],
              "data-testid": "billing-address-zipcode"
            }, null, 8, ["id", "modelValue", "class"]),
            (_ctx.errors['billing_address-zipcode'])
              ? (_openBlock(), _createBlock("small", _hoisted_44, _toDisplayString(_ctx.errors['billing_address-zipcode']), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_45, [
            _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.country')) + " *", 1),
            _createVNode(_component_InputText, {
              id: _ctx.InternalClient.id,
              modelValue: _ctx.InternalClient.billing_address.country,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_ctx.InternalClient.billing_address.country = $event)),
              class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['billing_address-country'] }],
              "data-testid": "billing-address-country"
            }, null, 8, ["id", "modelValue", "class"]),
            (_ctx.errors['billing_address-country'])
              ? (_openBlock(), _createBlock("small", _hoisted_46, _toDisplayString(_ctx.errors['billing_address-country']), 1))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createVNode("h5", _hoisted_47, _toDisplayString(_ctx.$t('fields.clients.mailboxAddress')), 1),
    _createVNode("div", _hoisted_48, [
      _createVNode("div", null, [
        _createVNode(_component_CheckBox, {
          modelValue: _ctx.InternalClient.mailbox_address_priority,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.InternalClient.mailbox_address_priority = $event)),
          binary: true,
          "data-testid": "billing-address-street",
          name: "use-mailbox"
        }, null, 8, ["modelValue"]),
        _createVNode("label", _hoisted_49, _toDisplayString(_ctx.$t('fields.clients.useMailbox')), 1)
      ])
    ]),
    _createVNode("div", _hoisted_50, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.mailboxNumber')), 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.mailbox.mailbox_number,
        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (_ctx.InternalClient.mailbox.mailbox_number = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['mailbox-mailbox_number'] }],
        "data-testid": "mailbox-street-number",
        placeholder: "Postbus nummer"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['mailbox-mailbox_number'])
        ? (_openBlock(), _createBlock("small", _hoisted_51, _toDisplayString(_ctx.errors['mailbox-mailbox_number']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_52, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.clients.zipcode')), 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.mailbox.zipcode,
        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (_ctx.InternalClient.mailbox.zipcode = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['mailbox-zipcode'] }],
        "data-testid": "mailbox-zipcode",
        placeholder: "1234AB"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['mailbox-zipcode'])
        ? (_openBlock(), _createBlock("small", _hoisted_53, _toDisplayString(_ctx.errors['mailbox-zipcode']), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_54, [
      _createVNode("label", null, _toDisplayString(_ctx.$t('fields.organizations.place')), 1),
      _createVNode(_component_InputText, {
        id: _ctx.InternalClient.id,
        modelValue: _ctx.InternalClient.mailbox.place,
        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_ctx.InternalClient.mailbox.place = $event)),
        class: [_ctx.$style.input, { 'p-invalid': _ctx.errors['mailbox-place'] }],
        "data-testid": "mailbox-place",
        placeholder: "Plaatsnaam"
      }, null, 8, ["id", "modelValue", "class"]),
      (_ctx.errors['mailbox-place'])
        ? (_openBlock(), _createBlock("small", _hoisted_55, _toDisplayString(_ctx.errors['mailbox-place']), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "lg:col-6 sm:col-12" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = {
  key: 0,
  class: "card field"
}
const _hoisted_5 = { class: "mb-0 pl-0 pt-0" }
const _hoisted_6 = {
  key: 1,
  class: "card field"
}
const _hoisted_7 = { class: "mb-0 pl-0 pt-0 p-2" }
const _hoisted_8 = { class: "mb-0 pl-0 pt-0 p-2" }
const _hoisted_9 = { class: "col-12 mb-0 pl-0 pt-0" }
const _hoisted_10 = { class: "p-px-6 flex flex-row justify-content-end mt-3" }
const _hoisted_11 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InvoiceForm = _resolveComponent("InvoiceForm")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_ctx.invoiceId === 0 && _ctx.templateOptions && _ctx.templateOptions.length)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('actions.selectInvoiceTemplate')), 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.invoiceTemplateId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.invoiceTemplateId = $event)),
                options: _ctx.templateOptions,
                optionLabel: "name",
                optionValue: "value",
                class: "col-6 p-0"
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (
						_ctx.invoiceId === 0 &&
						(
							_ctx.mustSelectMail ||
							!_ctx.selectedInvoiceTemplate.client
						)
					)
          ? (_openBlock(), _createBlock("div", _hoisted_6, [
              (!_ctx.selectedInvoiceTemplate.client)
                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                    _createVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t('actions.selectClient')), 1),
                    _createVNode(_component_Dropdown, {
                      filter: "",
                      modelValue: _ctx.currentClient,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentClient = $event)),
                      key: _ctx.currentClient,
                      options: _ctx.clients,
                      optionLabel: "name",
                      placeholder: "Kies een klant",
                      class: "col-6 p-0"
                    }, null, 8, ["modelValue", "options"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.mustSelectMail)
                ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                    _createVNode("h5", _hoisted_8, _toDisplayString(_ctx.$t('actions.selectMail')), 1),
                    _createVNode(_component_Dropdown, {
                      filter: "",
                      modelValue: _ctx.selectedContactPoint,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedContactPoint = $event)),
                      options: _ctx.reminderContactPoints,
                      optionLabel: "email",
                      placeholder: "SELECT_MAIL",
                      class: "col-6 p-0"
                    }, {
                      option: _withCtx((slotProps) => [
                        _createVNode("div", null, _toDisplayString(slotProps.option.email), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "options"])
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.templateOptions && _ctx.templateOptions.length)
          ? (_openBlock(), _createBlock("div", {
              key: _ctx.templateOptions,
              class: "card pt-4"
            }, [
              _createVNode("h5", _hoisted_9, _toDisplayString(_ctx.invoiceId > 0 ? _ctx.$t('actions.editInvoice') : _ctx.$t('actions.createInvoice')), 1),
              _createVNode("form", {
                onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
                class: "p-fluid"
              }, [
                _createVNode(_component_InvoiceForm, {
                  data: _ctx.invoice,
                  errors: _ctx.errors,
                  "onUpdate:invoice": _ctx.updateInvoiceFromForm,
                  "onUpdate:errors": _ctx.updateErrors
                }, null, 8, ["data", "errors", "onUpdate:invoice", "onUpdate:errors"]),
                _createVNode("div", _hoisted_10, [
                  _createVNode(_component_Button, {
                    icon: "pi pi-check",
                    label: _ctx.$tc('actions.addEdit', _ctx.invoiceId !== 0 ? 0 : 1),
                    class: "p-mr-2 mt-2 mr-1 ml-0 mb-2 !w-0",
                    type: "submit",
                    style: {"width":"150px"}
                  }, null, 8, ["label"]),
                  _createVNode(_component_Button, {
                    icon: "pi pi-times",
                    label: _ctx.$t('actions.cancel'),
                    class: "p-button-secondary mt-2 ml-1 mr-0 mb-2",
                    onClick: _ctx.back,
                    style: {"width":"150px"}
                  }, null, 8, ["label", "onClick"])
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.invoiceTemplates.length === 0)
          ? (_openBlock(), _createBlock("div", _hoisted_11, " No templates "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
import { useToast } from 'primevue/usetoast';
import { useStore } from '@/store';
import { Client, HttpInvoice, Invoice } from '@/interfaces';
import { InvoiceActions } from '@/store/modules/invoice';
import { INVOICE, INVOICE_CONFIRMATIONS } from '@/constants/messages';
import { ToastMessageOptions } from 'primevue/toast';
import useNotifications from '../useNotifications';

export default function useInvoiceConfirmations() {
	const store = useStore();
	const toast = useToast();
	const { createNotification } = useNotifications();

	const confirmInvoice = async (invoice: HttpInvoice) => {
		await updateStatus(invoice, 'Confirmed');
	};

	const confirmSend = async (invoice: HttpInvoice) => {
		await updateStatus(invoice, 'Sent');
	};

	const confirmPaid = async (invoice: HttpInvoice) => {
		await updateStatus(invoice, 'Paid', true);
	};

	const updateStatus = async (invoice: HttpInvoice, status: string, notify: boolean = false) => {
		const success = await store.dispatch(InvoiceActions.updateStatus, {
			id: invoice.id,
			organizationId: invoice.organization,
			status: status,
		});

		if ( !success ) {
			toast.add(INVOICE_CONFIRMATIONS.SEND_FAIL as ToastMessageOptions);
			return;
		}

		if (
			notify &&
			createNotification({
				title: 'Factuur betaald.',
				description: `Factuur '${ invoice.name }' is betaald door '${ invoice.client?.name }'.`,
				severity: 2,
			})
		) {
			toast.add(INVOICE.PAID(invoice.name) as ToastMessageOptions);
		} else {
			toast.add(INVOICE_CONFIRMATIONS.SEND_SUCCESS(invoice.name) as ToastMessageOptions);
		}
	};

	return {
		confirmInvoice,
		confirmSend,
		confirmPaid,
	};
}

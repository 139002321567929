
import { defineComponent, ref } from 'vue';
import { Button, Dialog } from '@/plugins/PrimeVue';
import moment from 'moment';

export default defineComponent({
	components: {
		Button,
		Dialog
	},
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
  emits: ['deleteNotification'],
	setup(props) {
		const displayModal = ref(false);
		const notificationItem = ref({ ...props.notification });
		notificationItem.value.createdAt = moment(notificationItem.value.createdAt).format('DD-MM-YYYY HH:mm:ss');

		const openModal = () => {
            displayModal.value = true;
		};
		const closeModal = () => {
				displayModal.value = false;
		}

		return { notificationItem, openModal, closeModal, displayModal };
	},
});

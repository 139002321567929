import { onMounted, computed } from 'vue';
import { useStore } from '@/store';
import { NotificationActions } from '@/store/modules/notification';
import { useToast } from 'primevue/usetoast';
import { NOTIFICATION } from '@/constants/messages';
import { HttpNotification } from '@/interfaces';
import { ToastMessageOptions } from "primevue/toast";

export default function useNotifications() {
	const store = useStore();
	const toast = useToast();

	const getNotifications = async () => {
		store.dispatch(NotificationActions.getAll);
	};

	const deleteNotification = async (notificationId: number) => {
		await store.dispatch(NotificationActions.delete, notificationId);

		return true;
	};

	const createNotification = (notification: HttpNotification) => {
		store.dispatch(NotificationActions.create, notification);
		getNotifications();
		return true;
	};

	onMounted(getNotifications);

	const notifications = computed(() => store.getters.notifications);
	const errors = computed(() => store.getters.errors);
	if (errors.value && errors.value.detail !== undefined) {
		toast.add(NOTIFICATION.ERROR(errors.value.detail) as ToastMessageOptions);
	}
	return {
		errors,
		notifications,
		getNotifications,
		deleteNotification,
		createNotification
	};
}

import { onMounted, computed, ref, watch } from 'vue';
import { useStore } from '@/store';
import { ClientActions } from '@/store/modules/client';
import { Client } from '@/interfaces';
import { cloneDeep } from 'lodash';
import { CLIENT } from '@/constants/messages';
import { useToast } from 'primevue/usetoast';
import { ToastMessageOptions } from "primevue/toast";

export default function useClient(watchStore: boolean = true, clientId: number = 0) {
	const store = useStore();
	const storedClient = computed(() => store.getters.client);
	const client = ref({
		mailbox: {}
	} as Client);
	const errors = computed(() => store.getters.clientErrors);
	const toast = useToast();

	const setClient = (existingClient: Client) => {
		client.value = cloneDeep(existingClient);
		if (!client.value.id && clientId) {
			client.value.id = clientId;
		}
	};

	const updateClient = (newClient: Client) => {
		client.value = newClient;
	};

	const setSelectedClient = (client: Client) => {
		store.dispatch(ClientActions.setSelected, client);
	}

	const saveClient = async () => {
		const dispatchMethod = clientId ? ClientActions.update : ClientActions.create;

		store.dispatch(ClientActions.resetErrors);
		await store.dispatch(dispatchMethod, client.value);

		if (Object.keys(errors.value).length) {
			if (errors.value && errors.value.detail !== undefined) {
				toast.add(CLIENT.ERROR(errors.value.detail) as ToastMessageOptions);
			}
			return false;
		}

		if (Object.keys(errors.value).length && errors.value && errors.value.detail !== undefined) {
			toast.add(CLIENT.ERROR(errors.value.detail) as ToastMessageOptions);
			return false;
		}

		const messageTemplate = clientId ? CLIENT.UPDATED : CLIENT.CREATED;
		toast.add(messageTemplate(client.value.name) as ToastMessageOptions);
		return true;
	};

	// Should be in useClients
	const deleteClient = async (payload: Client) => {
		await store.dispatch(ClientActions.delete, payload);

		if (Object.keys(errors.value).length && errors.value && errors.value.detail !== undefined) {
			toast.add(CLIENT.ERROR(errors.value.detail) as ToastMessageOptions);

			return false;
		}

		toast.add(CLIENT.DELETED() as ToastMessageOptions);
		return true;
	};

	const updateErrors = (newClient: Client) => {
		if (newClient != undefined) return;
		const inputErrors = errors.value;

		Object.keys(newClient).forEach((key) => {
			const value = newClient[key as keyof Client];
			if (value !== undefined && value !== null && value !== '') {
				delete inputErrors[key];
			}
		});

		store.dispatch(ClientActions.updateErrors, inputErrors);
	};

	const getClientById = (clientId: number, resetErr: boolean = true) => {
		store.dispatch(ClientActions.getSingle, clientId);

		if (resetErr) store.dispatch(ClientActions.resetErrors);
	}

	if (watchStore) {
		watch(storedClient, setClient);
	}

	if (clientId > 0) {
		onMounted(() => {
			getClientById(clientId, false);
		});
	}

	onMounted(() => {
		store.dispatch(ClientActions.resetErrors);
	});

	return {
		client,
		storedClient,
		getClientById,
		updateClient,
		saveClient,
		updateErrors,
		deleteClient,
		errors,
		setSelectedClient
	};
}

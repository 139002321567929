
import { defineComponent, ref } from 'vue';
import {HttpInvoice, Invoice} from '@/interfaces';
import { PButton, Tag, Dialog, Tooltip } from '@/plugins/PrimeVue';
import useInvoiceConfirmations from '@/composable/useInvoiceConfirmations';
import InvoiceForm from '@/components/InvoiceForm/InvoiceForm.vue';
import InvoicePreviewerVue from '@/views/Invoice/Previewer/InvoicePreviewer.vue';
import InvoicePdfPreviewer from '@/views/Invoice/PdfPreviewer/InvoicePdfPreviewer.vue';
import useInvoices from '@/composable/useInvoices';

export default defineComponent({
	components: {
		PButton,
		Tag,
		Dialog,
		InvoiceForm,
		InvoicePreviewerVue,
		InvoicePdfPreviewer,
	},
	directives: {
		tooltip: Tooltip,
	},
	props: {
		invoice: {
			type: Object as () => Invoice & HttpInvoice,
			default: {},
			required: true,
		},
	},
	setup(props) {
		const invoice = ref({...props.invoice});
		const { confirmInvoice, confirmPaid } = useInvoiceConfirmations();
		const { getInvoices } = useInvoices();

		const isDisplayOpen = ref(false);
		const openConfirmation = () => isDisplayOpen.value = true;
		const closeConfirmation = () => isDisplayOpen.value = false;

		const confirmAndClose = async () => {
			await confirmInvoice(invoice.value);
			isDisplayOpen.value = false;
			getInvoices();
		};

		const setPaid = async () => {
			await confirmPaid(invoice.value as HttpInvoice);
			isDisplayOpen.value = false;
			getInvoices();
		};

		return { isDisplayOpen, openConfirmation, closeConfirmation, confirmAndClose, setPaid };
	},
});

import { ActionContext, ActionTree } from 'vuex';
import { isEqual } from 'lodash';
import { api } from '@/api';
import { RootState } from '@/store/state';
import { Organization, OrganizationUpdate } from '@/interfaces';
import { createError } from '@/utils/createError';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import { checkValidity, ValidationItem } from "@/utils/validityChecker";
import { i18n } from '@/plugins/i18n';

export enum ActionTypes {
	getAll = 'ORGANIZATION__GET_ALL',
	getSingle = 'ORGANIZATION__GET_SINGLE',
	setSelected = 'ORGANIZATION__SET_SELECTED',
	create = 'ORGANIZATION__CREATE',
	update = 'ORGANIZATION__UPDATE',
	delete = 'ORGANIZATION__DELETE',
	resetErrors = 'ORGANIZATION__RESET_ERRORS',
	updateErrors = 'ORGANIZATION__UPDATE_ERRORS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

const requiredFieldTranslated = i18n.global.t("fields.errors.required");
const addressFields = [
	{ name: 'street', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'house_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'place', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'zipcode', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'country', message: requiredFieldTranslated, isObj: false, children: null },
];

const fields: Array<ValidationItem> = [
	{ name: 'name', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'kvk_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'btw_number', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'iban', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'bic', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'email', message: requiredFieldTranslated, isObj: false, children: null },
	{ name: 'phone_number', message: requiredFieldTranslated, isObj: false, children: null },

	{ name: 'billing_address', message: '', isObj: true, children: addressFields },
	{ name: 'postal_address', message: '', isObj: true, children: addressFields },
];

const create_required_fields = [
	{ name: 'logo', message: 'Vereist veld', isObj: false, children: null },
]

export interface Actions {
	[ActionTypes.getAll]({ commit }: AugmentedActionContext): void;
	[ActionTypes.getSingle]({ commit }: AugmentedActionContext, payload: number): void;
	[ActionTypes.setSelected]({ commit }: AugmentedActionContext, payload: Organization): void;
	[ActionTypes.create]({ commit }: AugmentedActionContext, payload: Organization): void;
	[ActionTypes.update]({ commit }: AugmentedActionContext, payload: OrganizationUpdate): Promise<boolean>;
	[ActionTypes.delete]({ commit }: AugmentedActionContext, payload: number): Promise<boolean>;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
	[ActionTypes.updateErrors]({ commit }: AugmentedActionContext, payload: {}): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getAll]({ commit }) {
		try {
			const response = await api.organization.getAll();
			if (response) {
				commit(MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.getSingle]({ commit }, payload) {
		try {
			const response = await api.organization.get(payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.setSelected]({ commit }, payload) {
		commit(MutationTypes.SET_SELECTED, payload);
	},
	async [ActionTypes.create]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, {});

		const errors = checkValidity([...fields, ...create_required_fields], payload);

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			return false;
		}

		try {
			const response = await api.organization.create(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.update]({ commit, getters }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		if (isEqual(getters.organization, payload)) {
			return false;
		}

		const errors = checkValidity(fields, payload);

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			return false;
		}

		try {
			const response = await api.organization.update(payload.id, payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.delete]({ commit }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		try {
			const response = await api.organization.delete(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
	async [ActionTypes.updateErrors]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, payload);
	},
};

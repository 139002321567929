import { onMounted, computed, watch } from 'vue';
import { useStore } from '@/store';
import { OrganizationActions } from '@/store/modules/organization';
import { useToast } from 'primevue/usetoast';
import { ORGANIZATION } from '@/constants/messages';
import { ToastMessageOptions } from "primevue/toast";

export default function useOrganizations() {
	const store = useStore();
	const toast = useToast();

	const getOrganizations = () => {
		store.dispatch(OrganizationActions.getAll);
	};

	onMounted(getOrganizations);

	const organizations = computed(() => store.getters.organizations);
	const selectedOrganization = computed(() => store.getters.selectedOrganization)
	const errors = computed(() => store.getters.errors);

	if (errors.value && errors.value.detail !== undefined) {
		toast.add(ORGANIZATION.ERROR(errors.value.detail) as ToastMessageOptions);
	}

	return {
		errors,
		organizations,
		selectedOrganization,
		getOrganizations,
	};
}

import { HttpCustomField } from "@/composable/useCustomField";
import { CustomField } from "@/interfaces";
import { ApiInstance } from "@/plugins/Axios"

function setCustomFieldApiURL(organizationId: number | undefined) {
	return `/api/organizations/${organizationId}/custom-fields`;
}

export const CustomFieldService = (api: ApiInstance) => ({
	async getAll(organizationId: number) {
		return api.get<CustomField[]>(setCustomFieldApiURL(organizationId))
	},
	async create(payload: HttpCustomField) {
		return api.post(setCustomFieldApiURL(payload.organization), payload)
	},
	async delete(organizationId: number, id: number) {
		return api.delete<CustomField>(`${setCustomFieldApiURL(organizationId)}/${id}`)
	}
})


import { CustomField } from "@/interfaces";
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		customField: {
			type: Object as () => CustomField,
		},
	},

	setup(props) {
		const iconType = () => {
			switch(props.customField?.type) {
				case 'text': return 'fa-solid fa-font'
				case 'number': return 'fa-solid fa-hashtag'
				case 'textarea': return 'fa-solid fa-file-lines'
				// TODO Add default icon
				default: return 'fa-solid fa-question'
			}
		}

		return {
			iconType
		}
	}
})

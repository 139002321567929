import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { Client } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	clients(state: S): Client[];
	tempClients(state: S): Client[];
	client(state: S): Client;
	selectedClient(state: S): Client;
	clientErrors(state: S): Record<string, string>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	clients: state => state.clients,
	tempClients: state => state.tempClients,
	client: state => {
		return state.client || ({} as Client);
	},
	selectedClient: state => state.selectedClient,
	clientErrors: state => state.errors,
};

import { MutationTree } from 'vuex';
import { InvoiceTemplate } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_ALL = 'SET_ALL_INVOICE_TEMPLATES',
	SET_SINGLE = 'SET_SINGLE_INVOICE_TEMPLATE',
	SET_SELECTED = 'SET_SELECTED_INVOICE_TEMPLATE',
	DELETE_SINGLE = 'DELETE_SINGLE_INVOICE_TEMPLATE',
	SET_ERRORS = 'SET_ERRORS_INVOICE_TEMPLATE',
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: InvoiceTemplate[]): void;
	[MutationTypes.SET_SINGLE](state: S, payload: InvoiceTemplate): void;
	[MutationTypes.SET_SELECTED](state: S, payload: InvoiceTemplate): void;
	[MutationTypes.DELETE_SINGLE](state: S, payload: InvoiceTemplate): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ALL](state, payload) {
		state.invoiceTemplates = payload;
	},
	[MutationTypes.SET_SINGLE](state, payload) {
		state.invoiceTemplate = payload;
	},
	[MutationTypes.SET_SELECTED](state, payload) {
		state.selectedInvoiceTemplate = payload;
	},
	[MutationTypes.DELETE_SINGLE](state, payload) {
		state.invoiceTemplates = state.invoiceTemplates.filter((invoiceTemplate: InvoiceTemplate) => {
			if (invoiceTemplate.id !== payload.id) {
				return invoiceTemplate;
			}
		});
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
};

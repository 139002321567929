
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DataTable, Column, Card, Tooltip } from '@/plugins/PrimeVue';
import { useConfirm } from 'primevue/useconfirm';
import { Client } from '@/interfaces';
import ConfirmDialog from 'primevue/confirmdialog';
import useClients from '@/composable/useClients';
import useClient from '@/composable/useClient';
import { dataTableFilter } from "@/utils/dataTableFilter";
import useOrganizations from '@/composable/useOrganizations';

export default defineComponent({
	components: {
		DataTable,
		Column,
		Card,
		ConfirmDialog,
	},
	directives: {
		tooltip: Tooltip,
	},
	setup() {
		const router = useRouter();
		const { clients, getClients, errors } = useClients();
		const { selectedOrganization } = useOrganizations();
		const confirm = useConfirm();
		const filter = ref(dataTableFilter());
		const loading = ref(false);
		const { deleteClient } = useClient(false);

		const toNew = () => {
			router.push({ name: 'client-create' });
		};

		watch(selectedOrganization, () => {
			getClients();
		});

		const toEdit = (id: number) => {
			router.push({ name: 'client-edit', params: { id } });
		};

		const toView = (id: number) => {
			router.push({ name: 'client-view', params: { id } });
		};

		const toDelete = async (client: Client) => {
			// This is a temporary solution
			if (window.confirm('weet u zeker dat u deze klant wilt verwijderen?')) {
				await deleteClient(client);
				getClients();
			}

			// following code is non-functional.
			// confirm.require({
			// 	message: `Are you sure you want to delete ${client.name}?`,
			// 	// header: 'Confirmation',
			// 	icon: 'pi pi-exclamation-triangle',
			// 	acceptClass: 'p-button-danger',
			// 	accept: async () => {
			// 		await deleteClient(client);
			// 		getClients();
			// 	},
			// });
		};

		return {
			clients,
			errors,
			toNew,
			toEdit,
			toView,
			toDelete,
			dataTableFilter,
			filter,
			loading,
			selectedOrganization
		};
	},
	methods: {
		clearFilter() {
			this.filter = dataTableFilter();
		},
	}
});

import { ToastSeverity } from "primevue/api";

export const toastTime = 6000;

export const INVOICE_CONFIRMATIONS = {
	CONFIRMED_SUCCESS: (id: string) => ({
		severity: 'success',
		summary: 'Invoice Successfully confirmed',
		detail: `Invoice ${id} confirmed`,
		life: toastTime,
	}),
	CONFIRMED_FAIL: {
		severity: 'error',
		summary: 'Invoice not confirmed',
		detail: 'something went wrong. please try again later',
		life: toastTime,
	},
	SEND_SUCCESS: (id: string) => ({
		severity: 'success',
		summary: 'Invoice Successfully send',
		detail: `Invoice ${id} send`,
		life: toastTime,
	}),
	SEND_FAIL: {
		severity: 'error',
		summary: 'Invoice not sent',
		detail: 'something went wrong. please try again later',
		life: toastTime,
	},
	PAID_SUCCESS: (id: string) => ({
		severity: 'success',
		summary: 'Invoice Successfully paid',
		detail: `Invoice ${id} paid`,
		life: toastTime,
	}),
	PAID_FAIL: {
		severity: 'error',
		summary: 'Invoice not paid',
		detail: 'something went wrong. please try again later',
		life: toastTime,
	},
};

export const INVOICE = {
	CREATED: (name: string) => ({
		severity: ToastSeverity.SUCCESS,
		summary: 'Factuur aangemaakt.',
		detail: `Factuur met de naam '${name}' is aangemaakt.`,
		life: toastTime,
	}),
	UPDATED: (name: string) => ({
		severity: ToastSeverity.SUCCESS,
		summary: 'Factuur aangepast.',
		detail: `Factuur met de naam '${name}' is aangepast.`,
		life: toastTime,
	}),
	PAID: (name: string) => ({
		severity: ToastSeverity.SUCCESS,
		summary: 'Factuur betaald.',
		detail: `Factuur met de naam '${name}' is betaald.`,
		life: toastTime,
	}),
	ERROR: (message: string) => ({
		severity: 'error',
		summary: 'Something went wrong. ',
		detail: `${message}`,
		life: toastTime,
	}),
};

export const INVOICE_TEMPLATE = {
	CREATED: (name: string) => ({
		severity: ToastSeverity.SUCCESS,
		summary: 'Factuur sjabloon aangemaakt.',
		detail: `Factuur sjabloon met de naam '${name}' is aangemaakt.`,
		life: toastTime,
	}),
	UPDATED: (name: string) => ({
		severity: ToastSeverity.SUCCESS,
		summary: 'Factuur sjabloon aangepast.',
		detail: `Factuur sjabloon met de naam '${name}' is aangepast.`,
		life: toastTime,
	}),
	ERROR: (message: string) => ({
		severity: 'error',
		summary: 'Something went wrong. ',
		detail: `${message}`,
		life: toastTime,
	}),
};

export const ORGANIZATION = {
	CREATED: (name: string) => ({
		severity: 'success',
		summary: `Organization ${name} successfully Created`,
		detail: '',
		life: toastTime,
	}),
	UPDATED: (name: string) => ({
		severity: 'success',
		summary: `Organization ${name} successfully Updated`,
		detail: '',
		life: toastTime,
	}),
	DELETED: () => ({
		severity: 'success',
		summary: 'Organization successfully Deleted',
		detail: '',
		life: toastTime,
	}),
	ERROR: (message: string) => ({
		severity: 'error',
		summary: 'Something went wrong. ',
		detail: `${message}`,
		life: toastTime,
	}),
};

export const NOTIFICATION = {
	CREATED: (name: string) => ({
		severity: 'success',
		summary: `Notification ${name} successfully Created`,
		detail: '',
		life: toastTime,
	}),
	UPDATED: (name: string) => ({
		severity: 'success',
		summary: `Notification ${name} successfully Updated`,
		detail: '',
		life: toastTime,
	}),
	DELETED: () => ({
		severity: 'success',
		summary: 'Notification successfully Deleted',
		detail: '',
		life: toastTime,
	}),
	ERROR: (message: string) => ({
		severity: 'error',
		summary: 'Something went wrong. ',
		detail: `${message}`,
		life: toastTime,
	}),
};

export const CLIENT = {
	CREATED: (name: string) => ({
		severity: 'success',
		summary: `Client ${name} successfully Created`,
		detail: '',
		life: toastTime,
	}),
	UPDATED: (name: string) => ({
		severity: 'success',
		summary: `Client ${name} successfully Updated`,
		detail: '',
		life: toastTime,
	}),
	DELETED: () => ({
		severity: 'success',
		summary: 'Client successfully Deleted',
		detail: '',
		life: toastTime,
	}),
	ERROR: (message: string) => ({
		severity: 'error',
		summary: 'Something went wrong. ',
		detail: `${message}`,
		life: toastTime,
	}),
};

export const CUSTOM_FIELD = {
	DELETED: () => ({
		severity: 'success',
		summary: 'Field successfully Deleted',
		detail: '',
		life: toastTime,
	}),
	ERROR: (message: string) => ({
		severity: 'error',
		summary: 'Something went wrong. ',
		detail: `${message}`,
		life: toastTime,
	}),
};

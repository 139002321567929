import { LocaleMessages } from "vue-i18n";

// @ts-ignore
export function supportedLocales(): LocaleMessages {
	const en_GB = require('../locales/en-GB.json');
	const nl_NL = require('../locales/nl-NL.json');

	return {
		en: en_GB,
		nl: nl_NL,
	};
}

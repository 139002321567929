import { appApiService } from '@/plugins/Axios';
import { ClientService } from '@/api/Client';
import { NotificationService } from '@/api/Notification';
import { InvoiceService } from './Invoice';
import { InvoiceTemplateService } from './InvoiceTemplate';
import { OrganizationService } from './Organization';
import { UserService } from './User';
import { CustomFieldService } from './CustomField';

export const api = {
	client: ClientService(appApiService),
	invoice: InvoiceService(appApiService),
	invoiceTemplate: InvoiceTemplateService(appApiService),
	notification: NotificationService(appApiService),
	organization: OrganizationService(appApiService),
	user: UserService(appApiService),
	customField: CustomFieldService(appApiService)
};


import { defineComponent } from 'vue';
import { InvoiceProperty } from '@/interfaces';
import { InputText, InputNumber, Textarea } from '@/plugins/PrimeVue';

export default defineComponent({
	components: {
		InputText,
		InputNumber,
    Textarea,
	},
	props: {
		input: {
			type: Object as () => InvoiceProperty,
			required: true,
		},
	},
});


import { defineComponent, ref, watch, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, Button, Card } from '@/plugins/PrimeVue';
import InvoiceTemplateForm from '@/components/InvoiceTemplateForm/InvoiceTemplateForm.vue';
import InvoiceForm from '@/components/InvoiceForm/InvoiceForm.vue';
import ClientForm from '@/components/ClientForm/ClientForm.vue';
import FormFields from "@/components/Widgets/Draggable/FormFields.vue";
import useInvoiceTemplate from '@/composable/useInvoiceTemplate';
import { Dropdown } from '@/plugins/PrimeVue';
import useClients from '@/composable/useClients';
import useOrganizations from '@/composable/useOrganizations';
import moment from "moment/moment";
import { InvoiceTemplate, Client } from "@/interfaces";
import CheckBox from "primevue/checkbox";

export default defineComponent({
	components: {
		Panel,
		Button,
		InvoiceTemplateForm,
		InvoiceForm,
		ClientForm,
		Card,
		FormFields,
		Dropdown,
		CheckBox,
	},
	setup() {
		moment.locale();
		const route = useRoute();
		const router = useRouter();

		const title = ref(route.meta.title);
		const invoiceTemplateId = parseInt(`${ route.params.id || 0 }`);
		const isNew = !invoiceTemplateId;
		const saveButtonLabel = isNew ? 'Aanmaken' : 'Aanpassen';

		const { organizations, selectedOrganization } = useOrganizations();
		const { clients, getClients } = useClients();

		const currentClient: Ref<Client | undefined> = ref();
		const selectedContactPoint = ref();
		const reminderContactPoints: Ref<Array<any>> = ref([]);

		const assignClient = ref(true);

		const {
			invoiceTemplate,
			storedInvoiceTemplate,
			updateInvoiceTemplate,
			saveInvoiceTemplate,
			updateErrors,
			getTemplateById,
			errors,
		} = useInvoiceTemplate(true);

		watch(invoiceTemplate, () => {
			if (
				invoiceTemplate.value &&
				invoiceTemplate.value.client &&
				invoiceTemplate.value.client > 0
			) {
				assignClient.value = true;
				currentClient.value =
					clients.value.find((client) => client.id === invoiceTemplate.value?.client) ??
					clients.value[0];
			} else {
				assignClient.value = false;
			}
		});

		watch(currentClient, () => {
			invoiceTemplate.value.client = currentClient.value?.id;

			reminderContactPoints.value = [];
			if (currentClient.value?.contacts && currentClient.value?.contacts.length > 0) {
				reminderContactPoints.value = currentClient.value?.contacts?.filter((contact) => {
					return contact.isReminderPoint;
				});
			}

			if (reminderContactPoints.value.length === 0) {
				invoiceTemplate.value.reminder_mail = currentClient.value?.email || '';
			}
		});

		watch(selectedContactPoint, () => {
			invoiceTemplate.value.reminder_mail = selectedContactPoint.value.email;
		});

		if (isNew) {
			currentClient.value = clients.value[0];
			selectedContactPoint.value = reminderContactPoints.value[0];

			watch(clients, () => {
				currentClient.value = clients.value[0];
			});
		}

		const getUsedData = (templateId: number) => {
			getClients();

			if (invoiceTemplateId !== 0) getTemplateById(invoiceTemplateId);
		}

		if (selectedOrganization.value.id) {
			getUsedData(invoiceTemplateId);
		}

		watch(selectedOrganization, () => {
			getUsedData(invoiceTemplateId);
		});

		const updateInvoiceInputFields = async (data: InvoiceTemplate) => {
			invoiceTemplate.value.inputs = data.inputs;
		}

		const back = () => {
			router.push({ name: 'invoice-template' });
		};

		const submit = async () => {
			const success = await saveInvoiceTemplate();

			if (success) {
				await router.push({name: 'invoices'});
			}
		};

		watch(assignClient, () => {
			currentClient.value = assignClient.value ? clients.value[0] : undefined;
		});

		return {
			assignClient,
			title,
			invoiceTemplate,
			storedInvoiceTemplate,
			updateInvoiceTemplate,
			submit,
			back,
			errors,
			updateErrors,
			saveButtonLabel,
			organizations,
			clients,
			isNew,
			currentClient,
			selectedContactPoint,
			reminderContactPoints,
			updateInvoiceInputFields
		};
	},
});

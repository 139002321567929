
import { defineComponent, ref, watch, Ref } from 'vue';
import { useRoute } from "vue-router";
import useInvoice from '@/composable/useInvoice/index';
import { Button } from '@/plugins/PrimeVue';
import useOrganizations from '@/composable/useOrganizations';
import { Organization } from '@/interfaces';
import DTH from "@/utils/dynamicTemplateHelper";
import { getAccessToken } from "@/utils/accessToken";

export default defineComponent({
	props: {
		invoiceId: {
			type: Number,
			required: false,
		},
		hasDownload: {
			type: Boolean,
			default: true
		}
	},
	components: {
		Button,
	},
	setup(props) {
		const route = useRoute();
		const invoiceId = props.invoiceId || +route.params.id || 0;
		const isCreate = !invoiceId;
		const invoiceTemplateId = ref(isCreate ? parseInt(`${route.query.template || 1}`, 10) : 0);
		const { invoice } = useInvoice(true, invoiceId);
		const { selectedOrganization } = useOrganizations();
		const hasDownload = ref(props.hasDownload);
		const imageUrl = ref('');
		const reminderMessage = ref('');

		const usedOrganization = ref<Organization>(
			selectedOrganization.value.postal_address && selectedOrganization.value.id !== 0 ?
				selectedOrganization.value :
				{postal_address: {}} as Organization
		);
		imageUrl.value = `${ process.env.VUE_APP_API_URL }/${ usedOrganization.value.logo }`;

		watch(selectedOrganization, (newVal) => {
			usedOrganization.value = newVal;
			imageUrl.value = `${ process.env.VUE_APP_API_URL }/${ usedOrganization.value.logo }`;
		});

		const formatter = new Intl.NumberFormat('nl-NL', {
			style: 'currency',
			currency: 'EUR',
		});

		interface PriceData {
			rawTotal: number,
			netTotal: number,
			vatTotal: number,
			perVatPercentage: Record<string, PartialPriceData>,
		}

		interface PartialPriceData {
			rawTotal: number,
			netTotal: number,
			percentage: number,
		}

		const priceData: Ref<PriceData> = ref({ rawTotal: 0, netTotal: 0, vatTotal: 0, perVatPercentage: {} });
		const totalWithoutVat = ref(0);
		const vatPrice = ref(0);
		const totalWithVat = ref(0);

		const updatePrices = () => {
			if (!invoice.value.propertyList) return;

			priceData.value = invoice.value.propertyList.reduce(
				(acc, obj) => {
					const onePercent = obj.price * 0.01;

					acc.rawTotal += obj.price;
					acc.netTotal += onePercent * (100 + (+obj.vat));
					acc.vatTotal += onePercent * (+obj.vat);

					if (!acc.perVatPercentage[obj.vat]) {
						acc.perVatPercentage[obj.vat] = {
							rawTotal: 0,
							netTotal: 0,
							percentage: +obj.vat,
						}
					}

					const vatObj = acc.perVatPercentage[obj.vat];

					vatObj.rawTotal += obj.price;
					vatObj.netTotal += obj.price / 100 * vatObj.percentage;

					return acc;
				},
				{ rawTotal: 0, netTotal: 0, vatTotal: 0, perVatPercentage: {} } as PriceData
			);

			vatPrice.value = totalWithoutVat.value * 0.21;
			totalWithVat.value = totalWithoutVat.value + vatPrice.value;
		}

		watch(invoice, () => {
			if (invoice.value.invoiceTemplateId) {
				invoiceTemplateId.value = invoice.value.invoiceTemplateId;
			}

			reminderMessage.value = DTH.replaceTemplate(
				invoice.value.reminder_messages[0],
				{
					nextInterval: `${invoice.value.paymentReminders[0]}`
				}
			);

			updatePrices();
		});

		const openPdfPage = async () => {
			const options = {
				headers: {
					Authorization: 'bearer ' + (getAccessToken() || '')
				}
			};

			const url = `${ process.env.VUE_APP_API_URL }/api/organizations/${selectedOrganization.value.id}/invoices/${invoice.value.id}/generate`

			const res = await (
				await fetch(url, options)
			).blob();

			const file = window.URL.createObjectURL(res);
			window.open(file, '_blank');
		}

		return {
			openPdfPage,
			reminderMessage,
			invoice,
			priceData,
			usedOrganization,
			formatter,
			hasDownload,
			imageUrl,
		};
	},
});

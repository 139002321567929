import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "field"
}
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = {
  key: 1,
  class: "whitespace-pre-wrap"
}
const _hoisted_5 = {
  key: 1,
  class: "field mb-4 pt-0 pb-0 w-20rem"
}
const _hoisted_6 = {
  key: 2,
  class: "field mb-4 pt-0 pb-0 w-20rem"
}
const _hoisted_7 = {
  key: 3,
  class: "field mb-4 pt-0 pb-0 w-20rem"
}
const _hoisted_8 = {
  key: 4,
  class: "field mb-4 pt-0 pb-0 w-20rem"
}
const _hoisted_9 = {
  key: 6,
  class: "field mb-4 pt-0 pb-0 w-20rem"
}
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_invoice_list_prop = _resolveComponent("invoice-list-prop")!
  const _component_setInvoiceReminder = _resolveComponent("setInvoiceReminder")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.invoice.inputs, (input, index) => {
      return (_openBlock(), _createBlock("div", null, [
        (_openBlock(), _createBlock("div", { key: _ctx.invoice }, [
          (input.id === 'description' || input.type === 'textarea')
            ? (_openBlock(), _createBlock("div", _hoisted_1, [
                (!_ctx.readOnly)
                  ? (_openBlock(), _createBlock("div", _hoisted_2, [
                      _createVNode("label", {
                        for: input.id
                      }, _toDisplayString(input.name) + ":", 9, ["for"]),
                      _createVNode(_component_Textarea, {
                        id: input.id,
                        class: "whitespace-pre-wrap",
                        modelValue: input.value,
                        "onUpdate:modelValue": ($event: any) => (input.value = $event),
                        tabindex: index,
                        rows: "3"
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "tabindex"]),
                      (_ctx.errors[input.id])
                        ? (_openBlock(), _createBlock("small", _hoisted_3, _toDisplayString(_ctx.errors[input.id]), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createBlock("p", _hoisted_4, _toDisplayString(input.value), 1))
              ]))
            : (input.name === 'Factuurnummer')
              ? (_openBlock(), _createBlock("div", _hoisted_5, [
                  _createVNode("label", null, _toDisplayString(input.name) + ":", 1),
                  _createVNode(_component_InputText, {
                    id: input.id,
                    modelValue: input.value,
                    "onUpdate:modelValue": ($event: any) => (input.value = $event),
                    disabled: "disabled",
                    tabindex: index
                  }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "tabindex"])
                ]))
              : (input.type === 'number')
                ? (_openBlock(), _createBlock("div", _hoisted_6, [
                    _createVNode("label", null, _toDisplayString(input.name) + ":", 1),
                    _createVNode(_component_InputText, {
                      id: input.id,
                      modelValue: input.value,
                      "onUpdate:modelValue": ($event: any) => (input.value = $event),
                      tabindex: index,
                      type: input.type
                    }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "tabindex", "type"])
                  ]))
                : (input.id === 'status')
                  ? (_openBlock(), _createBlock("div", _hoisted_7, [
                      _createVNode(_component_InputText, {
                        id: input.id,
                        modelValue: input.value,
                        "onUpdate:modelValue": ($event: any) => (input.value = $event),
                        hidden: "hidden",
                        tabindex: index
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "tabindex"])
                    ]))
                  : (input.id === 'createdAt')
                    ? (_openBlock(), _createBlock("div", _hoisted_8, [
                        _createVNode(_component_InputText, {
                          id: input.id,
                          modelValue: _ctx.dateToday,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dateToday = $event)),
                          hidden: "hidden",
                          tabindex: index
                        }, null, 8, ["id", "modelValue", "tabindex"])
                      ]))
                    : (input.id === 'products' && _ctx.invoicePropertyList)
                      ? (_openBlock(), _createBlock(_component_invoice_list_prop, {
                          key: 5,
                          property: _ctx.invoicePropertyList,
                          modelValue: input.value,
                          "onUpdate:modelValue": ($event: any) => (input.value = $event),
                          readOnly: _ctx.readOnly,
                          "onUpdate:property": ($event: any) => (_ctx.updatePropertyList(index, $event))
                        }, null, 8, ["property", "modelValue", "onUpdate:modelValue", "readOnly", "onUpdate:property"]))
                      : (_openBlock(), _createBlock("div", _hoisted_9, [
                          _createVNode("label", null, _toDisplayString(input.name) + ":", 1),
                          _createVNode(_component_InputText, {
                            id: input.id,
                            modelValue: input.value,
                            "onUpdate:modelValue": ($event: any) => (input.value = $event),
                            type: "text",
                            tabindex: index
                          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "tabindex"]),
                          (_ctx.errors[input.id])
                            ? (_openBlock(), _createBlock("small", _hoisted_10, _toDisplayString(_ctx.errors[input.id]), 1))
                            : _createCommentVNode("", true)
                        ]))
        ]))
      ]))
    }), 256)),
    _createVNode(_component_setInvoiceReminder, {
      "payment-terms": _ctx.paymentTerms,
      "onUpdate:paymentTerms": _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePaymentTerms($event)))
    }, null, 8, ["payment-terms"])
  ], 64))
}

import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, Button, Dropdown } from '@/plugins/PrimeVue';
import InvoiceForm from '@/components/InvoiceForm/InvoiceForm.vue';
import useInvoice from '@/composable/useInvoice/index';
import useInvoiceTemplate from "@/composable/useInvoiceTemplate/index";
import useInvoiceTemplates from '@/composable/useInvoiceTemplates';
import { HttpInvoice, InvoiceTemplate, Client } from "@/interfaces";
import useInvoices from "@/composable/useInvoices";
import moment from "moment";
import useOrganizations from '@/composable/useOrganizations';
import useClients from "@/composable/useClients";
import { createAddress } from "@/interfaces/Address";

export default defineComponent({
	components: {
		Panel,
		Button,
		Dropdown,
		InvoiceForm,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();

		const title = ref(route.meta.title);
		const invoiceId = +route.params.id || 0;

		const invoiceTemplateId = ref();
		const currentClient: Ref<Client> = ref({
			id: 0,
			name: '',
			email: '',
			contacts: [],
			kvk_number: '',
			btw_number: '',
			image: undefined,
			iban: '',
			bic: '',
			postal_address: createAddress(),
			billing_address: createAddress(),
			mailbox_address_priority: false,
			reminder_mail_mode: 0,
			organization: 0,
		});

		const { invoices } = useInvoices();
		const { selectedOrganization } = useOrganizations();

		const {
			storedInvoiceTemplate,
			getTemplateById
		} = useInvoiceTemplate(true, invoiceTemplateId.value);

		const { clients, getClients } = useClients();
		getClients();

		const {
			invoiceTemplates,
			selectedInvoiceTemplate,
			setSelectedInvoiceTemplate,
			getInvoiceTemplates,
			templateOptions
		} = useInvoiceTemplates();

		const selectedContactPoint = ref();
		
		const reminderContactPoints = computed(() => {
			return currentClient.value?.contacts?.filter((contact) => contact.isReminderPoint) || [];
		});

		const invoiceNumber = moment().format('YYYY-MM-DD') + '-000' + (invoices.value.length + 1);

		const {
			invoice,
			updateInvoice,
			saveInvoice,
			errors,
			updateErrors,
		} = useInvoice(true, invoiceId);

		const mustSelectMail = computed(() => {
			return (
				!selectedInvoiceTemplate.value.reminder_mail &&
				reminderContactPoints.value.length > 0
			);
		});

		const updateInvoiceFields = (data: HttpInvoice) => {
			const invoice = {
				id: invoiceId,
				name: invoiceNumber,
				reminder_mail:data.reminder_mail,
				inputs: data.inputs,
				paymentReminders: data.paymentReminders,
				propertyList: data.propertyList,
				organizationId: selectedOrganization.value.id,
				clientId: data.clientId || currentClient.value.id,
				invoiceTemplateId: invoiceTemplateId.value,
				reminder_messages: data.reminder_messages,
			};

			updateInvoice(invoice);
		}

		const updateInvoiceFromForm = (data: HttpInvoice) => {
			data.clientId = data.clientId ?? currentClient.value.id;
			invoice.value = data;

			updateInvoice(data);
		}

		const submit = async () => {
			const success = await saveInvoice();

			if (success) {
				await router.push({ name: 'invoices' });
			}
		};

		const back = () => {
			router.push({ name: 'invoices' });
		};

		const changeTemplate = () => {
			getTemplateById(invoiceTemplateId.value);
		}

		const applyTemplate = () => {
			if ( selectedInvoiceTemplate.value ) {
				invoice.value.inputs = selectedInvoiceTemplate.value.inputs;
				invoice.value.reminder_messages = selectedInvoiceTemplate.value.reminder_messages;

				const cId = selectedInvoiceTemplate.value.client;
				if (cId) {
					invoice.value.clientId = cId;
					currentClient.value = clients.value.find(client => client.id === cId) || currentClient.value;
				} else {
					invoice.value.clientId = currentClient.value.id || 0;
				}
			}

			determineInitialReminderPoint();
			updateInvoiceFields(invoice.value);
		};

		const determineInitialReminderPoint = () => {
			if (selectedInvoiceTemplate.value.reminder_mail) {
				invoice.value.reminder_mail = selectedInvoiceTemplate.value.reminder_mail;

				return;
			}

			if ( reminderContactPoints.value.length > 0 ) {
				selectedContactPoint.value = reminderContactPoints.value[0];
				invoice.value.reminder_mail = selectedContactPoint.value.email;
				return;
			}

			invoice.value.reminder_mail = currentClient.value?.email || '';
		};

		watch(selectedContactPoint, () => {
			if (
				selectedContactPoint.value &&
				!selectedInvoiceTemplate.value.reminder_mail
			) {
				invoice.value.reminder_mail = selectedContactPoint.value.email;
			}
		});

		watch(selectedOrganization, () => {
			getInvoiceTemplates();
			getClients();
		});

		watch(invoiceTemplates, () => {
			if ( !invoiceTemplateId.value && invoiceId === 0 ) {
				invoiceTemplateId.value = invoiceTemplates.value[0].id;

				changeTemplate();
			}
		});

		watch(clients, () => {
			currentClient.value = clients.value[0];
		});

		watch(invoiceTemplateId, () => {
			changeTemplate();
		});

		watch(storedInvoiceTemplate, () => {
			setSelectedInvoiceTemplate(storedInvoiceTemplate.value);
		});

		watch(selectedInvoiceTemplate, () => {
			applyTemplate();
		});

		currentClient.value = clients.value[0];
		watch(currentClient, () => {
			if ( !selectedInvoiceTemplate.value.client ) {
				invoice.value.clientId = currentClient.value.id;
			}

			determineInitialReminderPoint();
		});

		return {
			clients,
			currentClient,
			selectedInvoiceTemplate,
			title,
			invoice,
			updateInvoice,
			submit,
			back,
			updateInvoiceFromForm,
			errors,
			templateOptions,
			invoiceTemplates,
			invoiceId,
			invoiceTemplateId,
			reminderContactPoints,
			selectedContactPoint,
			updateInvoiceFields,
			changeTemplate,
			updateErrors,
			mustSelectMail,
		};
	},
});

export interface Mailbox {
	zipcode: string,
	place: string,
	mailbox_number: string,
}

export function createMailbox(): Mailbox {
	return {
		zipcode: '',
		place: '',
		mailbox_number: '',
	};
}

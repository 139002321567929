import { ActionContext, ActionTree } from 'vuex';
import { isEqual } from 'lodash';
import { api } from '@/api';
import { RootState } from '@/store/state';
import { apiKeyVariables, InvoiceTemplate } from '@/interfaces';
import { createError } from '@/utils/createError';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';

export enum ActionTypes {
	getAll = 'INVOICE_TEMPLATE__GET_ALL',
	getSingle = 'INVOICE_TEMPLATE__GET_SINGLE',
	setSelected = 'INVOICE_TEMPLATE__SET_SELECTED',
	create = 'INVOICE_TEMPLATE__CREATE',
	update = 'INVOICE_TEMPLATE__UPDATE',
	delete = 'INVOICE_TEMPLATE__DELETE',
	resetErrors = 'INVOICE_TEMPLATE__RESET_ERRORS',
	updateErrors = 'INVOICE_TEMPLATE__UPDATE_ERRORS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
	[ActionTypes.getAll]({ commit }: AugmentedActionContext, payload: number): void;
	[ActionTypes.getSingle]({ commit, getters }: AugmentedActionContext, payload: number): void;
	[ActionTypes.setSelected]({ commit }: AugmentedActionContext, payload: InvoiceTemplate): void;
	[ActionTypes.create]({ commit, getters }: AugmentedActionContext, payload: InvoiceTemplate): Promise<boolean>;
	[ActionTypes.update]({ commit }: AugmentedActionContext, payload: InvoiceTemplate): Promise<boolean>;
	[ActionTypes.delete]({ commit }: AugmentedActionContext, payload: InvoiceTemplate): Promise<boolean>;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
	[ActionTypes.updateErrors]({ commit }: AugmentedActionContext, payload: {}): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getAll]({ commit }, payload) {
		try {
			if (payload === 0) {
				return;
			}

			const response = await api.invoiceTemplate.getAll(payload);
			if (response) {
				commit(MutationTypes.SET_ALL, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.getSingle]({ commit, getters }, payload) {
		try {
			if (getters.selectedOrganization.id === 0 || payload === 0) {
				return;
			}

			const response = await api.invoiceTemplate.get(getters.selectedOrganization.id, payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.setSelected]({ commit }, payload) {
		commit(MutationTypes.SET_SELECTED, payload);
	},
	async [ActionTypes.create]({ commit, getters }, payload) {
		commit(MutationTypes.SET_ERRORS, {});

		const errors: Record<string, string> = {};

		if (!payload.name) {
			errors.name = 'name is required';
		}

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			return false;
		}

		try {
			if (getters.selectedOrganization.id === 0) {
				return false;
			}

			const response = await api.invoiceTemplate.create(getters.selectedOrganization.id, payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));

			return false;
		}

		return true;
	},
	async [ActionTypes.update]({ commit, getters }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		// const template = getters.invoiceTemplate;
		// if (isEqual(template, payload) || template.id !== payload.id) {
		// 	return false;
		// }

		const errors: Record<string, string> = {};
		if (payload.name.length === 0) {
			errors.name = 'field is required';
		}

		if (Object.keys(errors).length) {
			commit(MutationTypes.SET_ERRORS, errors);
			console.log(errors);
			return false;
		}

		try {
			if (getters.selectedOrganization.id === 0) {
				return false;
			}

			const response = await api.invoiceTemplate.update(getters.selectedOrganization.id, payload);

			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
		return true;
	},
	async [ActionTypes.delete]({ commit, getters }, payload): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		try {
			if (getters.selectedOrganization.id === 0) {
				return false;
			}

			const response = await api.invoiceTemplate.delete(getters.selectedOrganization.id, payload.id);

			if (response) {
				commit(MutationTypes.DELETE_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
	async [ActionTypes.updateErrors]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, payload);
	},
};

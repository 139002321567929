import { MutationTree } from 'vuex';
import { Organization } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_ALL = 'SET_ALL',
	SET_SINGLE = 'SET_SINGLE',
	SET_ERRORS = 'SET_ERRORS',
	SET_SELECTED = 'SET_SELECTED'
}

export type Mutations<S = State> = {
	[MutationTypes.SET_ALL](state: S, payload: Organization[]): void;
	[MutationTypes.SET_SINGLE](state: S, payload: Organization): void;
	[MutationTypes.SET_SELECTED](state: S, payload: Organization): void;
	[MutationTypes.SET_ERRORS](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ALL](state, payload) {
		state.organizations = payload;
	},
	[MutationTypes.SET_SELECTED](state, payload) {
		state.selectedOrganization = payload;
	},
	[MutationTypes.SET_SINGLE](state, payload) {
		state.organization = payload;
	},
	[MutationTypes.SET_ERRORS](state, payload) {
		state.errors = payload;
	},
};

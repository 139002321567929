
import { defineComponent, reactive, ref, toRefs, watch } from 'vue';
import { InputText, PButton } from '@/plugins/PrimeVue';
import { ContactPointUpdate } from '@/interfaces';
import CheckBox from "primevue/checkbox";

export default defineComponent({
	components: {
		CheckBox,
		InputText,
		PButton,
	},
	props: {
		client: {
			type: Object,
			default: () => ({}),
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:client'],
	setup(props, { emit }) {
		const defaultItem = Object.freeze(
			{
				isReminderPoint: false,
				email: '',
				description: '',
			}
		);
		const newItem = ref({ ...defaultItem });
		const clientRef = ref({ ...props.client });

		const addNewItem = () => {
			if ( newItem.value.description.length === 0 ) {
				return;
			}

			const item = {
				description: newItem.value.description,
				email: newItem.value.email,
				isReminderPoint: newItem.value.isReminderPoint,
			};

			clientRef.value = { ...clientRef.value, contacts: [item, ...(clientRef.value.contacts || [])] };
			newItem.value = { ...defaultItem };

			emit('update:client', clientRef.value.contacts);
		}

		const removeItem = (item: ContactPointUpdate) => {
			const newList = (clientRef.value.contacts || []).filter((i: ContactPointUpdate) => item !== i);

			// eslint-disable-next-line @typescript-eslint/camelcase
			clientRef.value = { ...clientRef.value, contacts: newList };

			emit('update:client', clientRef.value.contacts);
		}

		return {
			newItem,
			clientRef,
			defaultItem,
			removeItem,
			addNewItem,
		};
	},
});

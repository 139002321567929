export interface Address {
	street: string,
	house_number: number | null,
	house_number_addition: string | null,
	zipcode: string,
	place: string,
	country: string,
	full_address: string | null,
}

export function createAddress(): Address {
	return {
		country: '',
		full_address: '',
		house_number: null,
		house_number_addition: '',
		place: '',
		street: '',
		zipcode: ''
	};
}

import { onMounted, computed, ref, watch } from 'vue';
import { useStore } from '@/store';
import { OrganizationActions } from '@/store/modules/organization';
import { Organization } from '@/interfaces';
import { cloneDeep } from 'lodash';
import { ORGANIZATION } from '@/constants/messages';
import { useToast } from 'primevue/usetoast';
import { ToastMessageOptions } from "primevue/toast";

export default function useOrganization(watchStore: boolean = true, id: number = 0) {
	const store = useStore();
	const toast = useToast();

	const storedOrganization = computed(() => store.getters.organization);
	const organization = ref({
		postal_address: {},
		billing_address: {},
		mailbox: {},
	} as Organization);
	const errors = computed(() => store.getters.organizationErrors);

	const setOrganization = (existingOrganization: Organization) => {
		organization.value = cloneDeep(existingOrganization);
		if (!organization.value.id && id) {
			organization.value.id = id;
		}
	};

	const setSelectedOrganization = (organization: Organization) => {
		store.dispatch(OrganizationActions.setSelected, organization);
	}

	const updateOrganization = (newOrganization: Organization) => {
		organization.value = newOrganization;
	};

	const saveOrganization = async () => {
		const dispatchMethod = id ? OrganizationActions.update : OrganizationActions.create;

		await store.dispatch(dispatchMethod, organization.value);

		if (Object.keys(errors.value).length) {
			if (errors.value && errors.value.detail !== undefined) {
				toast.add(ORGANIZATION.ERROR(errors.value.detail) as ToastMessageOptions);
			}
			return false;
		}

		const messageTemplate = id ? ORGANIZATION.UPDATED : ORGANIZATION.CREATED;
		toast.add(messageTemplate(organization.value.name) as ToastMessageOptions);
		return true;
	};

	const deleteOrganization = async (organizationId: number) => {
		await store.dispatch(OrganizationActions.delete, organizationId);

		if (Object.keys(errors.value).length) {
			if (errors.value && errors.value.detail !== undefined) {
				toast.add(ORGANIZATION.ERROR(errors.value.detail) as ToastMessageOptions);
			}
			return false;
		}

		toast.add(ORGANIZATION.DELETED() as ToastMessageOptions);
		return true;
	};

	const updateErrors = (newOrganization: Organization) => {
		const inputErrors = errors.value;

		Object.keys(newOrganization).forEach((key) => {
			const value = newOrganization[key as keyof Organization];
			if (value !== undefined && value !== null && value !== '') {
				delete inputErrors[key];
			}
		});

		store.dispatch(OrganizationActions.updateErrors, inputErrors);
	};

	if (watchStore) {
		watch(storedOrganization, setOrganization)
	}

	if (id) {
		onMounted(() => {
			store.dispatch(OrganizationActions.getSingle, id);
		});
	}

	onMounted(() => {
		store.dispatch(OrganizationActions.resetErrors);
	});

	return {
		organization,
		storedOrganization,
		updateOrganization,
		saveOrganization,
		updateErrors,
		deleteOrganization,
		setSelectedOrganization,
		errors,
	};
}

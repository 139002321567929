import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "col-12 mb-2 lg:col-4 lg:col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", null, _toDisplayString(_ctx.item.description) + ":", 1),
    _createVNode("div", _hoisted_2, [
      _createVNode("span", {
        class: "p-input-icon-left",
        "data-testid": _ctx.item.description
      }, [
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.item.price,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.item.price = $event)),
          disabled: "disabled",
          currency: "EUR",
          locale: "nl-NL",
          mode: "currency"
        }, null, 8, ["modelValue"])
      ], 8, ["data-testid"])
    ])
  ]))
}

import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Panel, Button } from '@/plugins/PrimeVue';
import ClientForm from '@/components/ClientForm/ClientForm.vue';
import useClient from '@/composable/useClient';
import useOrganizations from '@/composable/useOrganizations';

export default defineComponent({
	components: {
		Panel,
		ClientForm,
		Button,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const title = ref(route.meta.title);
		const clientId = +route.params.id || 0;
		const saveButtonLabel = !clientId ? 'Aanmaken' : 'Aanpassen';

		const { organizations } = useOrganizations();

		const {
			client,
			saveClient,
			updateClient,
			updateErrors,
			errors,
		} = useClient(true, clientId);

		const submit = async () => {
			const success = await saveClient();
			if (success) {
				await router.push({ name: 'clients' });
			}
		};

		const submitUpdate = async () => {
			if (!confirm('Weet je zeker dat je de klant wilt aanpassen?')) {
				return;
			}

			const success = await saveClient();
			if (success) {
				await router.push({ name: 'clients' });
			}
		};

		const back = () => {
			router.push({ name: 'clients' });
		};

		return {
			title,
			client,
			clientId,
			back,
			saveButtonLabel,
			submit,
			errors,
			submitUpdate,
			updateClient,
			updateErrors,
			organizations
		};
	},
});


import { defineComponent, ref, watch } from 'vue';
import { InvoiceTemplate } from '@/interfaces';
import { InputText, InputNumber } from '@/plugins/PrimeVue';
import ClientForm from '@/components/ClientForm/ClientForm.vue';
import Draggable from "vuedraggable";
import useClient from '@/composable/useClient';

export default defineComponent({
	components: {
		ClientForm,
		Draggable,
		InputText,
		InputNumber,
	},
	props: {
		invoiceTemplate: {
			type: Object as () => InvoiceTemplate,
			required: true,
			default: {},
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const internalInvoiceTemplate = ref({...props.invoiceTemplate});
		const invoiceTemplatePropsList = ref();
		const { client, getClientById } = useClient(true, internalInvoiceTemplate.value.client);

		watch(() => props.invoiceTemplate, () => {
			internalInvoiceTemplate.value = { ...props.invoiceTemplate };

			if ( internalInvoiceTemplate.value.client ) getClientById(internalInvoiceTemplate.value.client);
		});

		return {
			internalInvoiceTemplate,
			invoiceTemplatePropsList,
			client
		};
	},
});

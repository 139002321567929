<template>
	<div class="layout-topbar">
		<div class="topbar-left">
			<Button icon="pi pi-bars" class="p-button-secondary" @click="onMenuButtonClick" />
			<router-link to="/" class="horizontal-logo">
				<img id="logo-horizontal" class="horizontal-logo" src="layout/images/logo-white.svg" alt="diamond-layout" />
			</router-link>

			<span class="topbar-separator"></span>

			<div class="layout-breadcrumb viewname" style="text-transform: uppercase">
				<template v-if="$route.meta.breadcrumb">
					<span>{{$route.meta.breadcrumb[0].label}}</span>
				</template>
			</div>

			<img id="logo-mobile" class="mobile-logo" src="layout/images/logo-dark.svg" alt="diamond-layout" />
		</div>
		<AppMenu
			:model="menu" :layoutMode="layoutMode" :active="menuActive"
			:mobileMenuActive="staticMenuMobileActive"
			@menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" @menu-button-click="onMenuButtonClick"></AppMenu>

		<div class="layout-mask modal-in"></div>

		<div class="topbar-right">
			<ul class="topbar-menu">
				<NotificationsList/>
				<Button
					icon="pi pi-power-off"
					@click="logout"
					label="Uitloggen"
				/>
			</ul>
		</div>
	</div>
</template>

<script>
import AppMenu from "@/AppMenu.vue";
import { UserActions } from "@/store/modules/user";
import NotificationsList from "@/components/NotificationsList/NotificationsList.vue"
import router from "@/router";
import store from "@/store";

export default {
	name: "AppTopbar",
	emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "search-click", "topbar-notification", "topbar-user-menu", "right-menubutton-click"],
	props: {
		topbarNotificationMenuActive: Boolean,
		topbarUserMenuActive: Boolean,
		layoutMode: String,
		menu: Array,
		menuActive: Boolean,
		staticMenuMobileActive: Boolean,
	},
	setup() {
		const logout = async () => {
			await store.dispatch(UserActions.logout);
			await router.push({name: 'login'});
		};

		return {
			logout,
		};
	},
	data() {
		return {
			items: [],
		};
	},
	components: {
		AppMenu,
		NotificationsList
	},
	unmounted() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	},
	methods: {
		onMenuClick(event) {
			this.$emit("menu-click", event);
		},
		onMenuItemClick(event) {
			this.$emit("menuitem-click", event);
		},
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		},
		onMenuButtonClick(event) {
			this.$emit("menu-button-click", event);
		},
		onSearchClick(event) {
			this.$emit("search-click", event);
		},
		onTopbarNotificationMenuButtonClick(event) {
			this.$emit("topbar-notification", event);
		},
		onTopbarUserMenuButtonClick(event) {
			this.$emit("topbar-user-menu", event);
		},
		onRightMenuClick(event) {
			this.$emit("right-menubutton-click", event);
		},
		isMobile() {
			return window.innerWidth <= 1091;
		},
	},
};
</script>

import { ActionContext, ActionTree } from "vuex";
import { MutationTypes, Mutations } from "./mutations";
import { State } from "./state";
import { RootState } from "@/store/state";
import { CustomField } from "@/interfaces";
import { api } from "@/api";
import { createError } from "@/utils/createError";
import { HttpCustomField } from "@/composable/useCustomField";

export enum ActionTypes {
	getAll = 'CUSTOM_FIELDS__GET_ALL',
	getSingle = "CUSTOM_FIELDS__GET_SINGLE",
	create = "CUSTOM_FIELDS__CREATE",
	delete = "CUSTOM_FIELDS__DELETE",
	resetErrors = 'CUSTOM_FIELDS__RESET_ERRORS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
	[ActionTypes.getAll]({ commit }: AugmentedActionContext, payload: number): void;
	[ActionTypes.create]({ commit }: AugmentedActionContext, payload: HttpCustomField): Promise<boolean>;
	[ActionTypes.delete]({ commit }: AugmentedActionContext, payload: { organization: number, id: number }): Promise<boolean>;
	[ActionTypes.resetErrors]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getAll]({ commit }, payload) {
		try {
			const response = await api.customField.getAll(payload);
			if (response) {
				commit(MutationTypes.SET_ALL, response.data)
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
	},
	async [ActionTypes.create]({ commit }, payload) {
		commit(MutationTypes.SET_ERRORS, {});

		const errors: Record<string, string> = {};

		if (!payload.organization) return false;
		if (!payload.type) errors.type = 'type is required';

		if (Object.keys(errors).length) {
			return false;
		}

		try {
			const response = await api.customField.create(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
		}
		return true;
	},
	async [ActionTypes.delete]({ commit }, { organization, id }: { organization: number, id: number }): Promise<boolean> {
		commit(MutationTypes.SET_ERRORS, {});

		try {
			const response = await api.customField.delete(organization, id);
			if (response) {
				commit(MutationTypes.DELETE_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERRORS, createError(error));
			return false;
		}
		return true;
	},
	async [ActionTypes.resetErrors]({ commit }) {
		commit(MutationTypes.SET_ERRORS, {});
	},
}

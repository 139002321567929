import { ApiInstance } from '@/plugins/Axios';
import { Client, ClientUpdate } from '@/interfaces';

function setClientApiURL(organizationId: number, clientId?: number) {
	return `/api/organizations/${organizationId}/clients/${clientId}`;
}

function getClientApiURL(organizationId: number) {
	return `/api/organizations/${organizationId}/clients`;
}

export const ClientService = (api: ApiInstance) => ({
	async getAll(organizationId: number) {
		return api.get<Client[]>(getClientApiURL(organizationId));
	},
	async get(organizationId: number, clientId: number) {
		return api.get<Client>(setClientApiURL(organizationId, clientId));
	},
	async create(organizationId: number, data: Client) {
		return api.post(getClientApiURL(organizationId), data);
	},
	async update(organizationId: number, clientId: number, data: ClientUpdate) {
		return api.put<Client>(setClientApiURL(organizationId, clientId), data);
	},
	async delete(organizationId: number, clientId: number) {
		return api.delete<Client>(setClientApiURL(organizationId, clientId));
	},
});

<template>
	<div class="exception-body notfound">
		<img src="layout/images/logo-white.svg" alt="diamond-layout" class="logo" />

		<div class="exception-content">
			<div class="exception-title">NOT FOUND</div>
			<div class="exception-detail">Requested resource is not available.</div>
			<router-link to="/">Go to Dashboard</router-link>
		</div>
	</div>
</template>

<script>
export default {};
</script>
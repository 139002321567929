
import { onMounted, computed } from 'vue';
import { useStore } from '@/store';
import { CustomFieldActions } from '@/store/modules/customField';
import { CUSTOM_FIELD } from '@/constants/messages';
import { useToast } from 'primevue/usetoast';
import { ToastMessageOptions } from "primevue/toast";

export default function useCustomFields() {
	const store = useStore();
	const toast = useToast();

	const getCustomFields = () => {
		if (
			!store.getters.selectedOrganization ||
			store.getters.selectedOrganization.id === undefined
		) {
			return;
		}

		const organization = store.getters.selectedOrganization.id;

		store.dispatch(CustomFieldActions.getAll, organization);
	};

	onMounted(getCustomFields);

	const customFields = computed(() => store.getters.customFields);

	const errors = computed(() => store.getters.customFieldErrors);
	if (errors.value && errors.value.detail !== undefined) {
		toast.add(CUSTOM_FIELD.ERROR(errors.value.detail) as ToastMessageOptions);
	}

	const deleteCustomField = async (organization: number, id: number) => {
		store.dispatch(CustomFieldActions.delete, { organization, id });
	}

	return {
		getCustomFields,
		deleteCustomField,
		customFields
	};
}

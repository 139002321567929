import { ref, onMounted, computed, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useStore } from '@/store';
import { InvoiceTemplate } from '@/interfaces';
import { InvoiceTemplateActions } from '@/store/modules/invoiceTemplate';
import { INVOICE_TEMPLATE } from '@/constants/messages';
import useNotifications from '../useNotifications';
import { ToastMessageOptions } from 'primevue/toast';

export default function useInvoiceTemplate(watchStore: boolean = true, templateId: number = 0) {
	const store = useStore();
	const toast = useToast();
	const storedInvoiceTemplate = computed(() => store.getters.invoiceTemplate);
	const invoiceTemplate = ref<InvoiceTemplate>({ id: 0, name: '', reminder_mail: '', inputs: [], reminder_messages: [
		'Bovenstaand totaalbedrag zien wij graag binnen $${nextInterval} dagen tegemoet op het onderstaande bankrekeningnummer met hierbij vermelding van het factuurnummer.',
		'Bovenstaand totaalbedrag zien wij graag binnen $${nextInterval} dagen tegemoet op het onderstaande bankrekeningnummer met hierbij vermelding van het factuurnummer. Mid.',
		'Bovenstaand totaalbedrag zien wij graag binnen $${nextInterval} dagen tegemoet op het onderstaande bankrekeningnummer met hierbij vermelding van het factuurnummer. Final.',
	]});
	const errors = computed(() => store.getters.invoiceTemplateErrors);
	const { createNotification } = useNotifications();

	const setInvoiceTemplate = (existingInvoiceTemplate: InvoiceTemplate) => {
		invoiceTemplate.value.id = existingInvoiceTemplate.id;
		invoiceTemplate.value.name = existingInvoiceTemplate.name;
	};

	const updateInvoiceTemplate = (newInvoice: InvoiceTemplate) => {
		invoiceTemplate.value = newInvoice;
	};

	const deleteInvoiceTemplate = async (invoiceTemplate: InvoiceTemplate) => {
		store.dispatch(InvoiceTemplateActions.delete, invoiceTemplate)
	};

	const saveInvoiceTemplate = async () => {
		const dispatchMethod = invoiceTemplate.value.id ? InvoiceTemplateActions.update : InvoiceTemplateActions.create;
		const success = await store.dispatch(dispatchMethod, invoiceTemplate.value);

		if (!success) {
			if (
				errors.value &&
				Object.keys(errors.value).length &&
				errors.value.detail !== undefined
			) {
				toast.add(INVOICE_TEMPLATE.ERROR(errors.value.detail) as ToastMessageOptions);
			} else {
				toast.add(INVOICE_TEMPLATE.ERROR('') as ToastMessageOptions);
			}

			return false;
		}

		if (dispatchMethod === InvoiceTemplateActions.update) {
			const notificationCreated = createNotification({
				title: 'Factuur sjabloon aangepast.',
				description: `'${invoiceTemplate.value.name}' is aangepast.`,
				severity: 1,
			});

			if (success && notificationCreated) {
				toast.add(INVOICE_TEMPLATE.UPDATED(invoiceTemplate.value.name) as ToastMessageOptions);
			} else {
				return false;
			}
		} else {
			const notificationCreated = createNotification({
				title: 'Factuur sjabloon aangemaakt.',
				description: `Factuur sjabloon met de naam '${invoiceTemplate.value.name}' is aangemaakt.`,
				severity: 1,
			});

			if (success && notificationCreated) {
				toast.add(INVOICE_TEMPLATE.CREATED(invoiceTemplate.value.name) as ToastMessageOptions);
			} else {
				return false;
			}
		}

		return true;
	};

	const updateErrors = (newInvoiceTemplate: InvoiceTemplate) => {
		const inputErrors = errors.value;
		// TODO rewrite to use reduce
		for (let i = 0; i < newInvoiceTemplate.inputs.length; i += 1) {
			const prop = newInvoiceTemplate.inputs[i];
			if (prop.items !== undefined && prop.items !== null && prop.items.length > 0) {
				delete inputErrors[prop.id];
			}
		}
		store.dispatch(InvoiceTemplateActions.updateErrors, inputErrors);
	};

	const getTemplateById = (templateId: number, resetErr: boolean = true) => {
		store.dispatch(InvoiceTemplateActions.getSingle, templateId);

		if (resetErr) store.dispatch(InvoiceTemplateActions.resetErrors);
	};

	if (watchStore) {
		watch(storedInvoiceTemplate, updateInvoiceTemplate);
	}

	if (templateId > 0) {
		onMounted(() => {
			getTemplateById(templateId, false);
		});
	}

	onMounted(() => {
		store.dispatch(InvoiceTemplateActions.resetErrors);
	});

	return {
		invoiceTemplate,
		storedInvoiceTemplate,
		getTemplateById,
		updateInvoiceTemplate,
		deleteInvoiceTemplate,
		saveInvoiceTemplate,
		updateErrors,
		errors,
	};
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "notification-item-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      type: "button",
      icon: "pi pi-bell text-2xl",
      class: "p-overlay-badge p-button-text p-button-secondary",
      badge: _ctx.notifications.length,
      badgeClass: "custom-badge-position p-badge-danger",
      disabled: !_ctx.notifications,
      onClick: _ctx.toggle,
      "aria:haspopup": "true",
      "aria-controls": "overlay_panel"
    }, null, 8, ["badge", "disabled", "onClick"]),
    _createVNode(_component_OverlayPanel, {
      label: "Toggle",
      ref: "op",
      appendTo: "body",
      showCloseIcon: false,
      id: "overlay_panel",
      class: "w-2",
      breakpoints: {'960px': '75vw'}
    }, {
      default: _withCtx(() => [
        (!_ctx.notifications.length)
          ? (_openBlock(), _createBlock("div", _hoisted_1, "Geen meldingen"))
          : (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("div", _hoisted_3, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
                  return (_openBlock(), _createBlock("div", { key: index }, [
                    _createVNode(_component_Notification, {
                      notification: notification,
                      onDeleteNotification: _ctx.clearNotification
                    }, null, 8, ["notification", "onDeleteNotification"])
                  ]))
                }), 128))
              ])
            ]))
      ]),
      _: 1
    }, 512)
  ], 64))
}